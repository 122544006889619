import { Title, Text, Group, Image } from "@mantine/core";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate();
  const getExplanationFontSize = () => {
    if (window.innerWidth < 768) {
      return "1.2rem"; // Adjust the font size for mobile devices
    }
    return "1.5rem"; // Default font size for larger screens
  };

  const handleHomepageButton = () => {
    navigate("/", { state: { tokenExpired: false } });
  };

  return (
    <>
      <div
        style={{
          borderBottom: "3px solid #71d8ce",
          paddingBottom: "2%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "80%",
            marginLeft: "10%", // Cut 10% from the left side
            marginRight: "10%", // Cut 10% from the right side
            paddingTop: window.innerWidth < 768 ? "8%" : "3%",
            paddingBottom: window.innerWidth < 768 ? "4%" : "0px",
          }}
        >
          <Group onClick={handleHomepageButton} style={{cursor: "pointer"}}>
            <Image
              maw={window.innerWidth < 768 ? 35 : 60}
              mx="auto"
              radius="md"
              src={require("../../images/logo.png")}
            />
            <Text
              style={{
                fontSize: window.innerWidth < 768 ? "1.2rem" : "27px",
                fontFamily: "Raleway",
                fontWeight: "700",
                color: "white",
              }}
            >
              ArticleWriter
            </Text>
          </Group>
        </div>
      </div>

      <Title
        order={1}
        style={{
          color: "white",
          fontSize: "5rem",
          marginBottom: "1rem",
          textAlign: "center",
          fontFamily: "Raleway",
        }}
      >
        404
      </Title>
      <Text
        style={{
          color: "white",
          fontSize: getExplanationFontSize(),
          marginBottom: "2rem",
          textAlign: "center",
          paddingLeft: 30,
          paddingRight: 30,
          fontFamily: "Raleway",
        }}
      >
        Oops! The page you're looking for doesn't exist.
      </Text>
    </>
  );
};

export default NotFoundPage;
