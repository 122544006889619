import { Coupon } from "./Coupon";

export class User {
  email: string;
  password: string;

  constructor(email: string, password: string) {
    this.email = email;
    this.password = password;
  }
}

export class UserView {
  email: string;
  creationTime: string;
  generatedContentNumber: number;
  usedTokenAmount: number;
  totalTokenAmount: number;
  totalCost: number;

  constructor(email: string, creationTime: string, generatedContentNumber: number, totalTokenAmount: number, usedTokenAmount: number, totalCost: number) {
    this.email = email;
    this.creationTime = creationTime;
    this.generatedContentNumber = generatedContentNumber;
    this.usedTokenAmount = usedTokenAmount;
    this.totalTokenAmount = totalTokenAmount;
    this.totalCost = totalCost;
  }
}

export class UserWithCoupons extends  UserView{
  coupons: Coupon []
  constructor(email: string, creationTime: string, generatedContentNumber: number, totalTokenAmount: number, usedTokenAmount: number, totalCost: number, coupons: Coupon []) {
    super(email, creationTime, generatedContentNumber, totalTokenAmount, usedTokenAmount, totalCost)
    this.coupons = coupons;
  }
}
