import { Group, Image, Text, Title, MantineProvider } from "@mantine/core";
import { IconCopyright } from "@tabler/icons-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getSecureLocalStorage, isTokenValid } from "../utilities/functions";
import styles from "../css/styles.module.css";

const Chat2DocsPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Chat2Docs";
    const token = getSecureLocalStorage("item1");

    if (token && isTokenValid(token)) {
      navigate("/homepage");
    }
  }, [navigate]);

  const handleHomepageButton = () => {
    navigate("/chat2docs");
  };

  return (
    <MantineProvider
      theme={{
        components: {
          Text: {
            styles: (theme, params) => ({
              root: {
                color: "white",
                fontFamily: "Raleway",
              },
            }),
          },
          Title: {
            styles: (theme, params) => ({
              root: {
                color: "white",
                fontFamily: "Raleway",
              },
            }),
          },
          Table: {
            styles: (theme, params) => ({
              root: {
                color: "white",
                fontFamily: "Raleway",
              },
            }),
          },
          th: {
            styles: (theme, params) => ({
              root: {
                color: "white",
                fontFamily: "Raleway",
              },
            }),
          },
          Button: {
            styles: (theme, params) => ({
              root: {
                fontFamily: "Raleway",
              },
            }),
          },
        },
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "80%",
            marginLeft: "10%", // Cut 10% from the left side
            marginRight: "10%", // Cut 10% from the right side
            height: "100%",
            paddingTop: window.innerWidth < 768 ? "6%" : "3%",
          }}
        >
          <Group onClick={handleHomepageButton} style={{ cursor: "pointer" }}>
            <Image
              maw={window.innerWidth < 768 ? 35 : 60}
              mx="auto"
              radius="md"
              src={require("../../images/logo.png")}
            />
            <Text
              style={{
                fontSize: window.innerWidth < 768 ? "1.2rem" : "27px",
                fontWeight: "700",
              }}
            >
              Chat2Docs
            </Text>
          </Group>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderTop: "3px solid #71d8ce",
            marginTop: "30px",
          }}
        >
          <Title
            style={{
              width: "70%",
              marginTop: "3%",
              fontSize: window.innerWidth < 768 ? "1.4rem" : "40px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Elevate Your ChatGPT Experience with Chat2Docs - Seamless
            Integration into Google Docs™
          </Title>
        </div>

        <div
          style={{
            width: "70%",
            marginLeft: "auto", // Center horizontally by setting left margin to auto
            marginRight: "auto", // Center horizontally by setting right margin to auto
            marginTop: "3%",
            alignItems: "left",
            fontSize: window.innerWidth < 768 ? "1rem" : "1.2rem",
            display: window.innerWidth < 768 ? "block" : "flex",
            justifyContent: "left",
            gap: "7%",
          }}
        >
          <div
            style={{
              backgroundImage: "linear-gradient(to right, #355667, #16222a)",
              width: window.innerWidth < 768 ? "100%" : "70%",
              borderRadius: "50px",
            }}
          >
            <Text
              style={{
                fontWeight: "200",
                paddingTop: "4%",
                paddingBottom: "4%",
                paddingRight: "4%",
                width: "80%",
              }}
            >
              Welcome to a new realm of convenience and productivity! Our
              innovative Chrome extension, Chat2Docs, is expertly crafted to
              enhance your ChatGPT experience. With this tool, you can
              effortlessly select and transfer ChatGPT's intelligent responses
              directly into Google Docs™. Whether you're compiling research,
              drafting documents, or organizing ideas, Chat2Docs streamlines
              the process, saving you time and effort.
            </Text>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: window.innerWidth < 768 ? "5%" : "2%",
            gap: "7%",
            borderBottom: "3px solid #71d8ce",
            paddingTop: window.innerWidth < 768 ? "5%" : "2%",
            paddingLeft: window.innerWidth < 768 ? "5%" : "0px",
            paddingRight: window.innerWidth < 768 ? "5%" : "0px",
          }}
        ></div>
        <div
          style={{
            width: "70%",
            marginLeft: "auto", // Center horizontally by setting left margin to auto
            marginRight: "auto", // Center horizontally by setting right margin to auto
            marginTop: window.innerWidth < 768 ? "10%" : "3%",
            alignItems: "center",
            fontSize: window.innerWidth < 768 ? "1rem" : "1.2rem",
            display: window.innerWidth < 768 ? "block" : "flex",
            justifyContent: "center",
            gap: "7%",
          }}
        >
          <Image
            maw={520}
            radius="md"
            mt={window.innerWidth < 768 ? "xl" : "0px"}
            src={require("../../images/Chat2Docs.png")}
          />
          <div style={{ display: "block", marginRight: "10%" }}>
            <div
              style={{
                marginTop: "10%",
              }}
            >
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: window.innerWidth < 768 ? "1.1rem" : "22px",
                  paddingBottom: "3%",
                }}
              >
                1- Easy Selection and Transfer
              </Text>
              <Text
                style={{
                  fontWeight: "200",
                  fontSize: window.innerWidth < 768 ? "1rem" : "20px",
                }}
              >
                Simply highlight ChatGPT's responses, and with a click, they're
                ready to be included in your Google Docs™.
              </Text>
            </div>

            <div
              style={{
                marginTop: "10%",
              }}
            >
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: window.innerWidth < 768 ? "1.1rem" : "22px",
                  paddingBottom: "3%",
                }}
              >
                2- Seamless Integration
              </Text>
              <Text
                style={{
                  fontWeight: "200",
                  fontSize: window.innerWidth < 768 ? "1rem" : "20px",
                }}
              >
                Our extension blends perfectly with your ChatGPT interface,
                ensuring a smooth, uninterrupted workflow.
              </Text>
            </div>

            <div
              style={{
                marginTop: "10%",
              }}
            >
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: window.innerWidth < 768 ? "1.1rem" : "22px",
                  paddingBottom: "3%",
                }}
              >
                3- Document Organization Made Simple
              </Text>
              <Text
                style={{
                  fontWeight: "200",
                  fontSize: window.innerWidth < 768 ? "1rem" : "20px",
                }}
              >
                Transform ChatGPT conversations into well-structured documents,
                ideal for study notes, business plans, or creative writing.
              </Text>
            </div>

            <div
              style={{
                marginTop: "10%",
              }}
            >
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: window.innerWidth < 768 ? "1.1rem" : "22px",
                  paddingBottom: "3%",
                }}
              >
                4- Effortless Collaboration
              </Text>
              <Text
                style={{
                  fontWeight: "200",
                  fontSize: window.innerWidth < 768 ? "1rem" : "20px",
                }}
              >
                Share your Google Docs™ with colleagues or friends, enhancing
                collaboration on projects initiated through ChatGPT dialogues.
              </Text>
            </div>
          </div>
        </div>

        <div
          style={{
            marginTop: window.innerWidth < 768 ? "10%" : "3%",
            marginLeft: "auto",
            marginRight: "auto",
            paddingBottom: "2%",
            display: window.innerWidth < 768 ? "block" : "flex",
            justifyContent: "center",
            width: "70%",
            textAlign: window.innerWidth < 768 ? "center" : "justify",
            fontSize: window.innerWidth < 768 ? "0.8rem" : "1rem",
          }}
        >
          <div style={{ display: "flex" }}>
            <IconCopyright color="white" style={{ marginRight: "10px" }} />
            <Text
              color="gray"
              style={{
                fontWeight: "100",
                textAlign: "center",
                marginBottom: "10px",
              }}
            >
              Chat2Docs is a product of ArticleWriter. All rights reserved.
            </Text>
          </div>
          <div>
            <a
              href="https://aiarticlewriter.org/chat2docs/privacy-policy"
              target="_blank"
              className={styles.aTags}
              style={{
                fontSize: window.innerWidth < 768 ? "0.8rem" : "1rem",
                textDecoration: "none",
                marginLeft: "5px",
                paddingBottom: "20px",
                paddingTop: "3px"
              }}
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </MantineProvider>
  );
};

export default Chat2DocsPage;
