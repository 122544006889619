import {
  Group,
  Image,
  MantineProvider,
  Space,
  Text,
  Title,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import styles from "../css/styles.module.css";
import { IconCopyright } from "@tabler/icons-react";
import { useEffect } from "react";

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Chat2Docs - Privacy Policy";
  });

  const handleHomepageButton = () => {
    navigate("/chat2docs");
  };

  return (
    <MantineProvider
      theme={{
        components: {
          Text: {
            styles: (theme, params) => ({
              root: {
                color: "white",
                fontFamily: "Raleway",
              },
            }),
          },
          Title: {
            styles: (theme, params) => ({
              root: {
                color: "white",
                fontFamily: "Raleway",
              },
            }),
          },
          List: {
            styles: (theme, params) => ({
              root: {
                color: "white",
                fontFamily: "Raleway",
              },
            }),
          },
          Table: {
            styles: (theme, params) => ({
              root: {
                color: "white",
                fontFamily: "Raleway",
              },
            }),
          },
          th: {
            styles: (theme, params) => ({
              root: {
                color: "white",
                fontFamily: "Raleway",
              },
            }),
          },
          Button: {
            styles: (theme, params) => ({
              root: {
                fontFamily: "Raleway",
              },
            }),
          },
        },
      }}
    >
      <div
        style={{
          borderBottom: "3px solid #71d8ce",
          paddingBottom: "2%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "80%",
            marginLeft: "10%", // Cut 10% from the left side
            marginRight: "10%", // Cut 10% from the right side
            height: "100%",
            paddingTop: window.innerWidth < 768 ? "6%" : "3%",
          }}
        >
          <Group onClick={handleHomepageButton} style={{ cursor: "pointer" }}>
            <Image
              maw={window.innerWidth < 768 ? 35 : 60}
              mx="auto"
              radius="md"
              src={require("../../images/logo.png")}
            />
            <Text
              style={{
                fontSize: window.innerWidth < 768 ? "1.2rem" : "27px",
                fontWeight: "700",
              }}
            >
              Chat2Docs
            </Text>
          </Group>
        </div>
      </div>
      <div
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          width: "80%",
          marginTop: "3%",
          marginLeft: "10%", // Cut 10% from the left side
          marginRight: "10%",
          paddingBottom: "2%",
        }}
      >
        <Title
          style={{
            fontSize: window.innerWidth < 768 ? "1.8rem" : "2.4rem",
            textAlign: "center",
          }}
        >
          Privacy Policy for Chat2Docs Chrome Extension
        </Title>
        <Space h="xl" />
        <Text
          style={{
            fontSize: window.innerWidth < 768 ? "0.9rem" : "1.2rem",
            textAlign: "justify",
          }}
        >
          <strong className={styles.privacyPolicyStrong}>
            1. Introduction:{" "}
          </strong>
          <div className={styles.privacyPolicyDiv}>
            Thank you for choosing Chat2Docs. This privacy policy aims to give
            you information on how our extension collects, uses, and protects
            your data when you use our service to transfer selected answers from
            ChatGPT to Google Docs™.
          </div>

          <strong className={styles.privacyPolicyStrong}>
            2. Data Collection:{" "}
          </strong>
          <div className={styles.privacyPolicyDiv}>
            Our extension does not collect or store personal data from its
            users. The only data interaction is the direct transfer of selected
            ChatGPT answers to Google Docs™.
          </div>

          <strong className={styles.privacyPolicyStrong}>
            3. Purpose of Data Collection:{" "}
          </strong>
          <div className={styles.privacyPolicyDiv}>
            The data is solely used to facilitate the automated upload of
            selected answers from ChatGPT to your Google Docs™.
          </div>

          <strong className={styles.privacyPolicyStrong}>
            4. Third-party Sharing:{" "}
          </strong>
          <div className={styles.privacyPolicyDiv}>
            We do not share your data with any third parties. The extension
            directly uploads your selected answers to your Google Docs™ without
            intermediary storage or sharing.
          </div>

          <strong className={styles.privacyPolicyStrong}>
            5. Data Storage:{" "}
          </strong>
          <div className={styles.privacyPolicyDiv}>
            Our extension does not store any of your data. All data transfers
            are immediate, from ChatGPT to Google Docs™, without any
            intermediate storage.
          </div>

          <strong className={styles.privacyPolicyStrong}>
            6. User Rights:{" "}
          </strong>
          <div className={styles.privacyPolicyDiv}>
            Since we do not collect or store personal data, there are no
            personal datasets that users would need to access, edit, or delete.
          </div>

          <strong className={styles.privacyPolicyStrong}>
            7. Use of Cookies:{" "}
          </strong>
          <div className={styles.privacyPolicyDiv}>
            Our extension does not use cookies.
          </div>

          <strong className={styles.privacyPolicyStrong}>
            8. Security Measures:{" "}
          </strong>
          <div className={styles.privacyPolicyDiv}>
            While our extension does not store data, we ensure secure data
            transmission from ChatGPT to Google Docs™.
          </div>

          <strong className={styles.privacyPolicyStrong}>
            9. Children’s Privacy:{" "}
          </strong>
          <div className={styles.privacyPolicyDiv}>
            Our extension is not designed to attract children under the age of
            13, and we do not knowingly collect data from children under 13.
          </div>

          <strong className={styles.privacyPolicyStrong}>
            10. Changes to the Privacy Policy:{" "}
          </strong>
          <div className={styles.privacyPolicyDiv}>
            Any changes made to this privacy policy in the future will be posted
            on this page. Please check back regularly to see any updates or
            changes.
          </div>

          <strong className={styles.privacyPolicyStrong}>
            11. Contact Information:{" "}
          </strong>
          <div className={styles.privacyPolicyDiv}>
            For questions or concerns about this privacy policy, please contact
            us at me@polatekrem.com
          </div>

          <strong className={styles.privacyPolicyStrong}>Disclosure: </strong>
          <div
            className={styles.privacyPolicyDiv}
            style={{ textAlign: "left" }}
          >
            Chat2Docs's use and transfer to any other app of information
            received from Google APIs will adhere to{" "}
            <a
              href="https://developers.google.com/terms/api-services-user-data-policy"
              target="_blank"
              className={styles.aTags}
              style={{ fontSize: window.innerWidth < 768 ? "1rem" : "1.2rem" }}
              rel="noopener noreferrer"
            >
              Google API Services User Data Policy
            </a>
            , including the Limited Use requirements.
          </div>

          <strong className={styles.privacyPolicyStrong}>Disclosure: </strong>
          <div
            className={styles.privacyPolicyDiv}
            style={{ textAlign: "left" }}
          >
            Chat2Docs does not share any data with the third party AI Models.
          </div>
        </Text>

        <div
          style={{
            marginTop: window.innerWidth < 768 ? "10%" : "3%",
            marginLeft: "auto",
            marginRight: "auto",
            paddingBottom: "2%",
            display: "flex",
            justifyContent: "center",
            width: "70%",
            textAlign: window.innerWidth < 768 ? "center" : "justify",
            fontSize: window.innerWidth < 768 ? "0.8rem" : "1rem",
          }}
        >
          <IconCopyright color="white" />
          <Text color="gray" style={{ marginLeft: "0.5%", fontWeight: "100" }}>
            Chat2Docs is a product of ArticleWriter. All rights reserved.
          </Text>
        </div>
      </div>
    </MantineProvider>
  );
};

export default PrivacyPolicy;
