import CryptoJS from "crypto-js";

export function isTokenValid(token_exp: string) {
  try {
    const token_exp_date = new Date(token_exp);
    const token_exp_ms = token_exp_date.getTime();
    const currentTime = Date.now();
    if (token_exp_ms < currentTime) {
      return false;
    }
    return true;
  } catch (error) {
    return false;
  }
}

export function setSecureLocalStorage(key: string, value: any) {
  try {
    const secretKey = process.env.REACT_APP_SECRET_KEY;
    if (secretKey !== undefined) {
      const ciphertext = CryptoJS.AES.encrypt(value, secretKey).toString();
      localStorage.setItem(`item_${key}`, ciphertext);
    } else {
      localStorage.setItem(`item_${key}`, value);
    }
  } catch (error) {
  }
}

export function getSecureLocalStorage(key: string) {
  try {
    let serializedValue = localStorage.getItem(`item_${key}`);
    if (serializedValue) {
      const secretKey = process.env.REACT_APP_SECRET_KEY;
      if (secretKey !== undefined) {
        const bytes = CryptoJS.AES.decrypt(serializedValue, secretKey);
        const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
        return decryptedText;
      } else {
        return serializedValue;
      }
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}

export function clearSecureLocalStorage() {
  try {
    localStorage.clear();
  } catch (error) {}
}
