import {
  Title,
  Text,
  Grid,
  Space,
  Card,
  Paper,
  Select,
  Group,
  CloseButton,
  Button,
  Modal,
  Loader,
  Textarea,
  Alert,
  Box,
  Collapse,
  TextInput,
  MantineProvider,
} from "@mantine/core";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  IconAlertCircle,
  IconChevronRight,
  IconCircleCheckFilled,
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import {
  clearSecureLocalStorage,
  getSecureLocalStorage,
  isTokenValid,
} from "../utilities/functions";
import { useDisclosure } from "@mantine/hooks";

export interface returnData {
  prompt: string;
  promptResult: string;
}

const CustomPromptPage = () => {
  const [model, setModel] = useState("GPT-3.5");
  const [language, setLanguage] = useState("English");
  const [tone, setTone] = useState("Formal");
  const [wordCount, setWordCount] = useState("500");
  const [prompts, setPrompts] = useState<string[]>([]);
  const [promptResults, setPromptResults] = useState<string[]>([]);
  const [singlePrompt, setSinglePrompt] = useState<string>("");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [generateButtonClicked, setGenerateButtonClicked] =
    useState<boolean>(false);
  const tableRef = useRef<HTMLTableElement>(null);
  const [showPage, setShowPage] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = useState<boolean>(false);

  const [openedArray, setOpenedArray] = useState<boolean[]>([]);

  const toggleArray = (index: number) => {
    const newOpenedArray = [...openedArray];
    newOpenedArray[index] = !newOpenedArray[index];
    setOpenedArray(newOpenedArray);
  };

  const [opened, { toggle }] = useDisclosure(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = getSecureLocalStorage("item1");

    if (!token || !isTokenValid(token)) {
      clearSecureLocalStorage();
      navigate("/", { state: { tokenExpired: true } });
    } else {
      setShowPage(true);
    }
  }, [navigate]);

  const handleModelChange = (value: string) => {
    setModel(value ? value : "");
  };

  const handleSinglePromptChange = (value: string) => {
    setSinglePrompt(value ? value : "");
  };

  const handleLanguageChange = (value: string) => {
    setLanguage(value ? value : "");
  };

  const handleToneChange = (value: string) => {
    setTone(value ? value : "");
  };

  const handleAddPromptPressed = () => {
    if (singlePrompt.length > 2) {
      let prevValues = [...prompts];
      prevValues.push(singlePrompt);
      setPrompts(prevValues);
      setSinglePrompt("");
    }
  };

  const handleClosePressed = (keyword: string) => {
    const index = prompts.indexOf(keyword);
    const updatedArray = [...prompts];
    updatedArray.splice(index, 1);
    setPrompts(updatedArray);
  };

  const handleSubmit = async () => {
    setOpenModal(true);
    setGenerateButtonClicked(true);
    let requestParams = {
      prompts: prompts,
      model: model,
      language: language,
      tone: tone,
      wordCount: wordCount,
    };
    try {
      const response = await axios.post(
        "https://app.aiarticlewriter.org/generate-custom-prompts",
        requestParams,
        {
          withCredentials: true,
        }
      );

      const responseData = response.data;

      const returnedPrompts = responseData.map(
        (item: returnData) => item.prompt
      );

      const returnedPromptResults = responseData.map(
        (item: returnData) => item.promptResult
      );

      setPrompts(returnedPrompts);
      setPromptResults(returnedPromptResults);
      setOpenModal(false);
    } catch (error: any) {
      setOpenModal(false);
      setOpenAlert(true);
    }
  };

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [promptResults]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [openAlert]);

  const getFontSize = () => {
    if (window.innerWidth < 768) {
      return "1.8rem"; // Adjust the font size for mobile devices
    }
    return "2.4rem"; // Default font size for larger screens
  };

  const getContentWidth = () => {
    if (window.innerWidth < 768) {
      return "90%"; // Adjust the font size for mobile devices
    }
    return "60%"; // Default font size for larger screens
  };

  const getExplanationSize = () => {
    if (window.innerWidth < 768) {
      return "1rem"; // Adjust the font size for mobile devices
    }
    return "1.2rem"; // Default font size for larger screens
  };

  const getAdvantagesSize = () => {
    if (window.innerWidth < 768) {
      return "0.8rem"; // Adjust the font size for mobile devices
    }
    return "1.1rem"; // Default font size for larger screens
  };

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.width = "auto";
      textareaRef.current.style.width = `${textareaRef.current.scrollWidth}px`;
    }
  }, []);

  return (
    <MantineProvider
      theme={{
        components: {
          Text: {
            styles: (theme, params) => ({
              root: {
                color: "white",
                fontFamily: "Raleway",
              },
            }),
          },
          Table: {
            styles: (theme, params) => ({
              root: {
                color: "white",
                fontFamily: "Raleway",
              },
            }),
          },
          Title: {
            styles: (theme, params) => ({
              root: {
                color: "white",
                fontFamily: "Raleway",
              },
            }),
          },
          Button: {
            styles: (theme, params) => ({
              root: {
                color: "white",
                fontFamily: "Raleway",
              },
            }),
          },
          Select: {
            styles: (theme, params) => ({
              root: {
                labelProps: {
                  style: {
                    fontFamily: "Raleway",
                    color: "white",
                    fontWeight: "bold",
                  },
                },
              },
            }),
          },
        },
      }}
    >
      {openAlert ? (
        <Alert
          icon={<IconAlertCircle size="1rem" />}
          title="Error!"
          color="red"
        >
          An error occured, please try again!
        </Alert>
      ) : (
        <div></div>
      )}
      {showPage ? (
        <>
          <Modal
            opened={openModal}
            onClose={() => setOpenModal(false)}
            withCloseButton={false}
            centered
            padding={20}
            withOverlay={false}
            withinPortal={true}
          >
            <Grid align="center">
              <Grid.Col span={12}>
                <Text style={{ fontFamily: "Raleway", color: "black" }}>
                  Your contents are being created. This may take a few
                  minutes...
                </Text>
              </Grid.Col>
            </Grid>
            <Grid align="center" justify="center">
              <Loader />
            </Grid>
          </Modal>

          <Grid justify="center" align="center">
            <Grid.Col span={8}>
              <Title
                style={{
                  marginBottom: "1rem",
                  marginTop: "1rem",
                  textAlign: "center",
                  fontSize: getFontSize(),
                }}
              >
                Heading: Empower Your Website with GPT-Driven Results
                Generation: Harnessing the Power of Bulk Action!
              </Title>
            </Grid.Col>
          </Grid>
          <Grid justify="center" align="center">
            <Grid.Col span={8}>
              <Text
                style={{
                  marginBottom: "1rem",
                  textAlign: "center",
                  fontSize: getExplanationSize(),
                }}
              >
                Welcome to our cutting-edge website that revolutionizes the way
                you generate results using the immense power of GPT (Generative
                Pre-trained Transformer) technology. Say goodbye to laborious
                and time-consuming manual processing; our platform is designed
                to streamline your tasks by offering bulk action result
                generation. Whether you're looking to analyze data, process
                text, generate creative content, or make complex decisions, our
                GPT-driven solution enables your users to effortlessly input
                their queries and receive comprehensive results at an
                unprecedented scale. Embrace the efficiency of bulk action and
                unlock the true potential of your website with our
                transformative GPT-powered results generator. Let's elevate your
                user experience to new heights together!
              </Text>
            </Grid.Col>
            <Grid.Col span={10}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Group>
                  <IconCircleCheckFilled color="white" size={20} />
                  <Text style={{ fontSize: getAdvantagesSize() }}>
                    Custom prompt
                  </Text>
                </Group>
              </div>
              <Space h={"md"} />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Group>
                  <IconCircleCheckFilled color="white" size={20} />
                  <Text style={{ fontSize: getAdvantagesSize() }}>
                    Bulk Action
                  </Text>
                </Group>
              </div>
              <Space h={"md"} />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Group>
                  <IconCircleCheckFilled color="white" size={20} />
                  <Text style={{ fontSize: getAdvantagesSize() }}>
                    Supported by GPT-3.5 and GPT-4
                  </Text>
                </Group>
              </div>
              <Space h={"md"} />
            </Grid.Col>
            <Space h="md" />
            <Grid.Col span={12}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Card
                  style={{
                    width: getContentWidth(),
                    alignItems: "center",
                    backgroundColor: "transparent",
                  }}
                >
                  <Grid justify="center">
                    <Grid.Col span={10}>
                      <Paper style={{ backgroundColor: "transparent" }}>
                        <Select
                          placeholder="Select GPT model"
                          label="Model:"
                          labelProps={{
                            style: {
                              fontFamily: "Raleway",
                              color: "white",
                              fontWeight: "bold",
                            },
                          }}
                          data={[
                            { value: "GPT-3.5", label: "GPT-3.5" },
                            { value: "GPT-4", label: "GPT-4" },
                          ]}
                          nothingFound="No model found"
                          value={model}
                          onChange={handleModelChange}
                          disabled={generateButtonClicked}
                        />
                      </Paper>
                    </Grid.Col>
                    <Space h="md" />
                    <Grid.Col span={10}>
                      <Paper style={{ backgroundColor: "transparent" }}>
                        <Select
                          placeholder="Select your language"
                          label="Language:"
                          labelProps={{
                            style: {
                              fontFamily: "Raleway",
                              color: "white",
                              fontWeight: "bold",
                            },
                          }}
                          data={[
                            { value: "English", label: "English" },
                            { value: "Turkish", label: "Turkish" },
                            { value: "German", label: "German" },
                            { value: "Spanish", label: "Spanish" },
                            { value: "French", label: "French" },
                            { value: "Italian", label: "Italian" },
                          ]}
                          nothingFound="No model found"
                          value={language}
                          onChange={handleLanguageChange}
                          disabled={generateButtonClicked}
                        />
                      </Paper>
                    </Grid.Col>
                    <Space h="md" />
                    <Grid.Col span={10}>
                      <Paper style={{ backgroundColor: "transparent" }}>
                        <Select
                          placeholder="Select your tone"
                          label="Tone:"
                          labelProps={{
                            style: {
                              fontFamily: "Raleway",
                              color: "white",
                              fontWeight: "bold",
                            },
                          }}
                          data={[
                            { value: "Formal", label: "Formal" },
                            { value: "Informal", label: "Informal" },
                            { value: "Friendly", label: "Friendly" },
                            { value: "Humorous", label: "Humorous" },
                            {
                              value: "Conversational",
                              label: "Conversational",
                            },
                          ]}
                          nothingFound="No model found"
                          value={tone}
                          onChange={handleToneChange}
                          disabled={generateButtonClicked}
                        />
                      </Paper>
                    </Grid.Col>
                    <Space h="md" />
                    <Grid.Col span={10}>
                      <Paper style={{ backgroundColor: "transparent" }}>
                        <TextInput
                          placeholder="Select maximum word count"
                          label="Max Word Count:"
                          labelProps={{
                            style: {
                              fontFamily: "Raleway",
                              color: "white",
                              fontWeight: "bold",
                            },
                          }}
                          type="number"
                          value={wordCount}
                          onChange={(event) => {
                            const input = event.currentTarget.value.replace(
                              /,/g,
                              ""
                            );
                            const number = Number(input);
                            if (!isNaN(number) && number <= 1000) {
                              setWordCount(input);
                            }
                          }}
                          disabled={generateButtonClicked}
                        />
                        <Text italic size={"sm"}>
                          You can generate a maximum of 1,000 words of content.
                        </Text>
                      </Paper>
                    </Grid.Col>
                    <Space h="md" />
                    <Grid.Col span={10}>
                      <Paper style={{ backgroundColor: "transparent" }}>
                        <Textarea
                          label="Prompt:"
                          labelProps={{
                            style: {
                              fontFamily: "Raleway",
                              color: "white",
                              fontWeight: "bold",
                            },
                          }}
                          minRows={6}
                          placeholder="Enter your prompt"
                          onChange={(event) =>
                            handleSinglePromptChange(event.currentTarget.value)
                          }
                          value={singlePrompt}
                          disabled={generateButtonClicked}
                          error={singlePrompt.length < 3}
                        />
                      </Paper>
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <Button
                        style={{
                          color: "black",
                          fontFamily: "Raleway",
                        }}
                        type="submit"
                        variant="gradient"
                        gradient={{ from: "white", to: "#FFFC15", deg: 60 }}
                        onClick={handleAddPromptPressed}
                        disabled={
                          generateButtonClicked || singlePrompt.length < 3
                        }
                      >
                        Add
                      </Button>
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <>
                        {prompts.map((prompt) => (
                          <>
                            <Textarea
                              autosize
                              disabled
                              style={{ width: "100%" }}
                              styles={{
                                input: {
                                  border: "2px solid #cccccc",
                                  borderRadius: 0,
                                  WebkitTextFillColor: "black",
                                  fontFamily: "Raleway",
                                },
                              }}
                              rightSection={
                                <CloseButton
                                  aria-label="Remove tag"
                                  disabled={generateButtonClicked}
                                  onClick={() => handleClosePressed(prompt)}
                                />
                              }
                              ref={textareaRef}
                            >
                              {prompt}
                            </Textarea>
                            <Space h="sm" />
                          </>
                        ))}
                      </>
                    </Grid.Col>
                    <Space h="sm" />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <Button
                        style={{
                          color: "black",
                          fontFamily: "Raleway",
                        }}
                        type="submit"
                        variant="gradient"
                        gradient={{ from: "white", to: "#FFFC15", deg: 60 }}
                        onClick={handleSubmit}
                        disabled={generateButtonClicked || prompts.length === 0}
                      >
                        Generate
                      </Button>
                    </div>
                    <Space h="xl" />
                    <Grid.Col span={12}>
                      {promptResults.map((result, index) => (
                        <Box mx="auto" key={index}>
                          <Space h="xl" />
                          <Group
                            position="center"
                            mb={5}
                            onClick={() => toggleArray(index)}
                            style={{ cursor: "pointer", color: "white" }}
                          >
                            <IconChevronRight></IconChevronRight>
                            <Text style={{ fontWeight: "bold" }}>
                              {prompts[promptResults.indexOf(result)]}
                            </Text>
                          </Group>

                          <Collapse in={openedArray[index]}>
                            <Text>{result}</Text>
                          </Collapse>
                        </Box>
                      ))}
                    </Grid.Col>
                  </Grid>
                </Card>
              </div>
            </Grid.Col>
          </Grid>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: window.innerWidth > 724 ? "100px" : "0px",
          }}
        >
          <Loader />
        </div>
      )}
    </MantineProvider>
  );
};

export default CustomPromptPage;
