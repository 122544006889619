import React, { useState } from "react";
import { Route, Routes, useNavigate, Outlet } from "react-router-dom";

import LoginPage from "./components/pages/Login";
import NotFoundPage from "./components/pages/NotFoundPage";
import SignupPage from "./components/pages/Signup";
import GeneratedContents from "./components/pages/GeneratedContents";
import HomePage from "./components/pages/HomePage";
import {
  AppShell,
  Image,
  Group,
  MediaQuery,
  Navbar,
  useMantineTheme,
  Text,
  NavLink,
  Header,
  Burger,
  MantineProvider,
} from "@mantine/core";
import {
  IconAdjustmentsHorizontal,
  IconArticle,
  IconBrandWechat,
  IconChecklist,
  IconChevronRight,
  IconGiftCard,
  IconHome2,
  IconLogout,
  IconPrompt,
  IconUserCircle,
  IconUsers,
} from "@tabler/icons-react";
import {
  getSecureLocalStorage,
  clearSecureLocalStorage,
} from "./components/utilities/functions";
import Users from "./components/pages/adminPages/UsersPage";
import LandingPage from "./components/pages/LandingPage";
import Pricing from "./components/pages/Pricing";
import Profile from "./components/pages/Profile";
import CouponsPage from "./components/pages/adminPages/Coupons";
import CustomPromptPage from "./components/pages/CustomPrompt";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import CustomChatBot from "./components/pages/CustomChatBot";
import Chat2DocsPage from "./components/pages/Chat2Docs";

const App: React.FC = () => {
  const navigate = useNavigate();
  const isAdmin = getSecureLocalStorage("item2");

  const handleLogout = () => {
    setOpened((o) => !o);
    clearSecureLocalStorage();
    navigate("/", { state: { tokenExpired: false } });
  };

  const handleProfileClick = () => {
    setOpened((o) => !o);
    navigate("/homepage/profile");
  };

  const handleHomepageClick = () => {
    setOpened((o) => !o);
    navigate("/homepage");
  };

  const handleCustomPromptClick = () => {
    setOpened((o) => !o);
    navigate("/homepage/custom");
  };

  const handleContentsClick = () => {
    setOpened((o) => !o);
    navigate("/homepage/contents");
  };

  const handleCustomChatBotClick = () => {
    setOpened((o) => !o);
    navigate("/homepage/custom-chatbot");
  };

  const handleUsersClick = () => {
    setOpened((o) => !o);
    navigate("/homepage/users");
  };

  const handleCouponsPageClick = () => {
    setOpened((o) => !o);
    navigate("/homepage/coupons");
  };

  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/signup" element={<SignupPage />} />
      <Route path="/chat2docs/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/chat2docs" element={<Chat2DocsPage />} />
      <Route
        path="/homepage"
        element={
          <MantineProvider
            theme={{
              components: {
                NavLink: {
                  styles: (theme, params) => ({
                    root: {
                      backgroundColor:
                        params.variant === "filled"
                          ? theme.colors[params.color || theme.primaryColor][9]
                          : undefined,
                      "&:hover": {
                        backgroundColor:
                          params.variant === "filled" ? "#ddd" : "transparent",
                      },
                    },
                  }),
                },
              },
            }}
          >
            <AppShell
              navbar={
                <Navbar
                  p="md"
                  hiddenBreakpoint="sm"
                  hidden={!opened}
                  width={{ sm: 200, lg: 300 }}
                  style={{
                    display:
                      opened || window.innerWidth > 724 ? "flex" : "none",
                    justifyContent: "space-between",
                    backgroundImage:
                      "linear-gradient(to right, #3a5f72, #335364)",
                    borderRight: "3px solid #71d8ce",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <NavLink
                      label=<Text
                        style={{
                          fontFamily: "Raleway",
                          color: "white",
                        }}
                      >
                        Create Content
                      </Text>
                      icon={
                        <IconHome2 size="1.2rem" stroke={1.5} color="white" />
                      }
                      childrenOffset={28}
                      rightSection={
                        <IconChevronRight
                          size="0.8rem"
                          stroke={1.5}
                          color="white"
                        />
                      }
                    >
                      <NavLink
                        style={{
                          transition: "gray 0.3s ease",
                        }}
                        label=<Text
                          style={{
                            fontFamily: "Raleway",
                            color: "white",
                          }}
                        >
                          Generate Article
                        </Text>
                        icon={
                          <IconArticle
                            size="1.2rem"
                            stroke={1.5}
                            color="white"
                          />
                        }
                        onClick={handleHomepageClick}
                      />
                      <NavLink
                        label=<Text
                          style={{
                            fontFamily: "Raleway",
                            color: "white",
                          }}
                        >
                          Custom Prompt
                        </Text>
                        icon={
                          <IconPrompt
                            size="1.2rem"
                            stroke={1.5}
                            color="white"
                          />
                        }
                        onClick={handleCustomPromptClick}
                      />
                    </NavLink>
                    <NavLink
                      label=<Text
                        style={{
                          fontFamily: "Raleway",
                          color: "white",
                        }}
                      >
                        Previous Contents
                      </Text>
                      icon={
                        <IconChecklist
                          size="1.2rem"
                          stroke={1.5}
                          color="white"
                        />
                      }
                      onClick={handleContentsClick}
                    />
                    <NavLink
                      label=<Text
                        style={{
                          fontFamily: "Raleway",
                          color: "white",
                        }}
                      >
                        Custom ChatBot
                      </Text>
                      icon={
                        <IconBrandWechat
                          size="1.2rem"
                          stroke={1.5}
                          color="white"
                        />
                      }
                      onClick={handleCustomChatBotClick}
                    />
                    {isAdmin === "User is admin" ? (
                      <>
                        <NavLink
                          label=<Text
                            style={{
                              fontFamily: "Raleway",
                              color: "white",
                            }}
                          >
                            Admin Panel
                          </Text>
                          icon={
                            <IconAdjustmentsHorizontal
                              size="1.2rem"
                              stroke={1.5}
                              color="white"
                            />
                          }
                          childrenOffset={28}
                          rightSection={
                            <IconChevronRight
                              size="0.8rem"
                              stroke={1.5}
                              color="white"
                            />
                          }
                        >
                          <NavLink
                            label=<Text
                              style={{
                                fontFamily: "Raleway",
                                color: "white",
                              }}
                            >
                              Users
                            </Text>
                            icon={
                              <IconUsers
                                size="1.2rem"
                                stroke={1.5}
                                color="white"
                              />
                            }
                            onClick={handleUsersClick}
                          />
                          <NavLink
                            label=<Text
                              style={{
                                fontFamily: "Raleway",
                                color: "white",
                              }}
                            >
                              Coupons
                            </Text>
                            icon={
                              <IconGiftCard
                                size="1.2rem"
                                stroke={1.5}
                                color="white"
                              />
                            }
                            onClick={handleCouponsPageClick}
                          />
                        </NavLink>
                      </>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <div>
                    <NavLink
                      label={
                        <Text
                          style={{
                            fontFamily: "Raleway",
                            color: "white",
                          }}
                        >
                          Profile
                        </Text>
                      }
                      icon={
                        <IconUserCircle
                          size="1.2rem"
                          stroke={1.5}
                          color="white"
                        />
                      }
                      onClick={handleProfileClick}
                    />
                    <NavLink
                      label={
                        <Text style={{ color: "red", fontFamily: "Raleway" }}>
                          Logout
                        </Text>
                      }
                      icon={
                        <IconLogout size="1.2rem" color="red" stroke={1.5} />
                      }
                      onClick={handleLogout}
                    />
                  </div>
                </Navbar>
              }
              header={
                <Header
                  height={{ base: 50, md: 100 }}
                  p="md"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "3%",
                    borderBottom: "3px solid #71d8ce",
                    backgroundImage:
                      "linear-gradient(to right, #3a5f72, #16222a)",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <MediaQuery largerThan="sm" styles={{ display: "none" }}>
                      <Burger
                        opened={opened}
                        onClick={() => setOpened((o) => !o)}
                        size="sm"
                        color={theme.colors.gray[6]}
                        mr="xl"
                      />
                    </MediaQuery>
                    <Group onClick={handleHomepageClick} style={{cursor: "pointer",}}>
                      <Image
                        maw={window.innerWidth < 768 ? 35 : 60}
                        mx="auto"
                        radius="md"
                        src={require("../src/images/logo.png")}
                      />
                      <Text
                        style={{
                          fontSize: window.innerWidth < 768 ? "1.2rem" : "27px",
                          fontFamily: "Raleway",
                          fontWeight: "700",
                          color: "white",
                        }}
                      >
                        ArticleWriter
                      </Text>
                    </Group>
                  </div>
                </Header>
              }
            >
              <Outlet />
            </AppShell>
          </MantineProvider>
        }
      >
        <Route index element={<HomePage />} />
        <Route path="contents" element={<GeneratedContents />} />
        <Route path="users" element={<Users />} />
        <Route path="coupons" element={<CouponsPage />} />
        <Route path="profile" element={<Profile />} />
        <Route path="custom" element={<CustomPromptPage />} />
        <Route path="custom-chatbot" element={<CustomChatBot />} />
      </Route>

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default App;
