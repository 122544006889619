import React, { useEffect } from "react";
import {
  Card,
  TextInput,
  Button,
  Text,
  Grid,
  Title,
  Alert,
  LoadingOverlay,
  Image,
  Modal,
  Space,
  Group,
  Footer,
} from "@mantine/core";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { User } from "../models/User";
import {
  IconAlertCircle,
  IconCheck,
  IconCopyright,
  IconLock,
  IconMail,
} from "@tabler/icons-react";
import {
  getSecureLocalStorage,
  isTokenValid,
  setSecureLocalStorage,
} from "../utilities/functions";

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [openAlert, setOpenAlert] = useState<boolean>(false);

  const [successAlert, setSuccessAlert] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const [codeIsWrong, setCodeIsWrong] = useState(false);
  const [passwordNotMatch, setPasswordNotMatch] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState<string>("");

  const [passResetUserEmail, setPassResetUserEmail] = useState<string>("");
  const [openResetPassModal, setOpenResetPassModal] = useState<boolean>(false);
  const [resetPassEmail, setResetPassEmail] = useState<string>("");
  const [alertMessage, setAlertMessage] = useState<string>(
    "Invalid Credentials"
  );
  const [resetPassEmailCode, setResetPassEmailCode] = useState<string>("");
  const [isResetPasswordSent, setIsResetPasswordSent] =
    useState<boolean>(false);

  const [openPasswordEntranceModal, setOpenPasswordEntranceModal] =
    useState<boolean>(false);
  const [modalEmailCode, setModalEmailCode] = useState<string>("");
  const [modalPass, setModalPass] = useState<string>("");
  const [modalConfPass, setModalConfPass] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    const token = getSecureLocalStorage("item1");

    if (token && isTokenValid(token)) {
      navigate("/homepage");
    }
  }, [navigate]);

  const handleSendConfirmationEmailModal = async () => {
    try {
      setIsResetPasswordSent(true);
      const params = {
        email: resetPassEmail,
      };
      const response = await axios.post(
        "https://app.aiarticlewriter.org/send-reset-password-confirmation-email",
        params
      );

      const code = response.data.code;
      setPassResetUserEmail(resetPassEmail);
      setResetPassEmailCode(code);
      setIsResetPasswordSent(false);
      setOpenPasswordEntranceModal(true);
      setOpenResetPassModal(false);
    } catch {
      setAlertMessage("An error has occured! Please check back later!");
      setOpenPasswordEntranceModal(false);
      setIsResetPasswordSent(false);
      setOpenResetPassModal(false);
      setOpenAlert(true);
      setResetPassEmail("");
    }
  };

  const handlePasswordReset = async () => {
    try {
      setIsResetPasswordSent(true);
      if (resetPassEmailCode === modalEmailCode) {
        if (modalPass === modalConfPass) {
          if (modalPass.length >= 5) {
            setPasswordNotMatch(false);
            setCodeIsWrong(false);
            const params = {
              email: passResetUserEmail,
              password: modalPass,
            };
            await axios.post(
              "https://app.aiarticlewriter.org/change-user-password-from-login",
              params
            );

            setOpenPasswordEntranceModal(false);
            setOpenResetPassModal(false);
            setIsResetPasswordSent(false);
            setSuccessAlert(true);
            setResetPassEmail("");
          } else {
            setEmailErrorMsg("Password length should be at least 5!");
            setPasswordNotMatch(true);
            setIsResetPasswordSent(false);
            setCodeIsWrong(false);
          }
        } else {
          setEmailErrorMsg("Passwords do not match!");
          setPasswordNotMatch(true);
          setIsResetPasswordSent(false);
          setCodeIsWrong(false);
        }
      } else {
        setCodeIsWrong(true);
        setPasswordNotMatch(false);
        setIsResetPasswordSent(false);
      }
    } catch {
      setAlertMessage("An error has occured! Please check back later!");
      setOpenPasswordEntranceModal(false);
      setIsResetPasswordSent(false);
      setOpenResetPassModal(false);
      setOpenAlert(true);
    }
  };

  const handleLogin = async () => {
    setIsLoading(true);
    const params = new User(email, password);
    axios.defaults.withCredentials = true;

    try {
      const loginResponse = await axios.post(
        "https://app.aiarticlewriter.org/check-user-credentials",
        params
      );

      if (loginResponse.status === 200) {
        setSecureLocalStorage("item1", loginResponse.data.exp);
        const adminCheckResponse = await axios.post(
          "https://app.aiarticlewriter.org/check-admin",
          {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (adminCheckResponse.status === 200) {
          const is_admin = adminCheckResponse.data.is_admin;
          if (is_admin) {
            setSecureLocalStorage("item2", "User is admin");
          } else {
            setSecureLocalStorage("item2", "User is not admin");
          }
        }

        navigate("/homepage", { replace: true });
      }
    } catch (error: any) {
      setAlertMessage("Invalid Credentials!");
      setOpenAlert(true);
      setIsLoading(false);
    }
  };

  const handleSignUp = () => {
    navigate("/signup", { replace: true });
  };

  const handleResetPassModal = () => {
    setOpenResetPassModal(true);
  };

  const handleNavigateHomePage = () => {
    navigate("/", { state: { tokenExpired: false } });
  };

  return (
    <div
      style={{
        overflowX: "hidden",
        overflowY: "hidden",
        fontFamily: "Raleway",
        height: "100vh",
      }}
    >
      {openAlert ? (
        <Alert
          icon={<IconAlertCircle size="1rem" />}
          title="Error!"
          color="red"
        >
          {alertMessage}
        </Alert>
      ) : (
        <div></div>
      )}
      {successAlert ? (
        <Alert icon={<IconCheck size="1rem" />} title="Success!" color="green">
          Your password is successfully changed!
        </Alert>
      ) : (
        <div></div>
      )}
      <div
        style={{
          borderBottom: "3px solid #71d8ce",
          paddingBottom: "2%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "80%",
            marginLeft: "10%", // Cut 10% from the left side
            marginRight: "10%", // Cut 10% from the right side
            paddingTop: window.innerWidth < 768 ? "8%" : "3%",
            paddingBottom: window.innerWidth < 768 ? "4%" : "0px",
          }}
        >
          <Group onClick={handleNavigateHomePage} style={{cursor: "pointer"}}>
            <Image
              maw={window.innerWidth < 768 ? 35 : 60}
              mx="auto"
              radius="md"
              src={require("../../images/logo.png")}
            />
            <Text
              style={{
                fontSize: window.innerWidth < 768 ? "1.2rem" : "27px",
                fontFamily: "Raleway",
                fontWeight: "700",
                color: "white",
              }}
            >
              ArticleWriter
            </Text>
          </Group>
        </div>
      </div>

      <Modal
        opened={openResetPassModal}
        size={window.innerWidth < 700 ? 275 : 350}
        title=<Text
          style={{
            fontWeight: "bold",
            fontSize: window.innerWidth < 700 ? 20 : 24,
            fontFamily: "Raleway"
          }}
        >
          Reset Password
        </Text>
        onClose={() => {
          setResetPassEmail("");
          setOpenResetPassModal(false);
        }}
        withCloseButton
        centered
        padding={20}
        withinPortal={true}
      >
        <TextInput
          icon=<IconMail />
          mb={"md"}
          value={resetPassEmail}
          size={window.innerWidth < 700 ? "xs" : "md"}
          placeholder="Email"
          required
          autoComplete="off"
          onChange={(event) => setResetPassEmail(event.currentTarget.value)}
        />
        <Space h={"md"} />
        <div style={{ justifyContent: "center", display: "flex" }}>
          <Button
            variant="gradient"
            gradient={{ from: "white", to: "#FFFC15", deg: 60 }}
            onClick={() => handleSendConfirmationEmailModal()}
            disabled={resetPassEmail === "" || !resetPassEmail.includes("@")}
            style={{
              fontSize: window.innerWidth < 700 ? "0.75rem" : "1rem",
              fontFamily: "Raleway",
              color: "black"
            }}
          >
            Send Code
            <LoadingOverlay
              visible={isResetPasswordSent}
              overlayOpacity={0.8}
              loaderProps={{ size: 24 }}
            ></LoadingOverlay>
          </Button>
        </div>
      </Modal>
      <Modal
        opened={openPasswordEntranceModal}
        size={window.innerWidth < 700 ? 275 : 350}
        title=<Text
          style={{
            fontWeight: "bold",
            fontSize: window.innerWidth < 700 ? 20 : 24,
            fontFamily: "Raleway"
          }}
        >
          Reset Password
        </Text>
        onClose={() => {
          setResetPassEmail("");
          setOpenPasswordEntranceModal(false);
        }}
        withCloseButton
        centered
        padding={20}
        withinPortal={true}
      >
        <TextInput
          mb={"md"}
          value={modalEmailCode}
          size={window.innerWidth < 700 ? "xs" : "md"}
          label="Code"
          placeholder="Enter the code sent your email"
          required
          autoComplete="off"
          error={codeIsWrong ? "Code is wrong!" : null}
          onChange={(event) => setModalEmailCode(event.currentTarget.value)}
        />
        <Space h={"md"} />
        <TextInput
          mb={"md"}
          value={modalPass}
          size={window.innerWidth < 700 ? "xs" : "md"}
          label="Password"
          placeholder="Enter your new password"
          required
          type="password"
          error={passwordNotMatch ? emailErrorMsg : null}
          autoComplete="off"
          onChange={(event) => setModalPass(event.currentTarget.value)}
        />
        <Space h={"md"} />
        <TextInput
          mb={"md"}
          value={modalConfPass}
          size={window.innerWidth < 700 ? "xs" : "md"}
          label="Password Again"
          placeholder="Enter your new password again"
          required
          type="password"
          error={passwordNotMatch ? emailErrorMsg : null}
          autoComplete="off"
          onChange={(event) => setModalConfPass(event.currentTarget.value)}
        />
        <Space h={"md"} />
        <div style={{ justifyContent: "center", display: "flex" }}>
          <Button
            onClick={() => handlePasswordReset()}
            variant="gradient"
            gradient={{ from: "white", to: "#FFFC15", deg: 60 }}
            style={{
              fontSize: window.innerWidth < 700 ? "0.75rem" : "1rem",
              fontFamily: "Raleway",
              color: "black"
            }}
          >
            Update
            <LoadingOverlay
              visible={isResetPasswordSent}
              overlayOpacity={0.8}
              loaderProps={{ size: 24 }}
            ></LoadingOverlay>
          </Button>
        </div>
      </Modal>
      <Grid justify="center">
        <Grid.Col span={8}>
          <Card
            style={{
              maxWidth: 400,
              margin: window.innerWidth < 768 ? "10% auto" : "3% auto",
              padding: "3%",
              border: "3px solid #71d8ce",
              borderRadius: "10px",
              backgroundColor: "#18262f",
            }}
            shadow="xl"
          >
            <Title
              mb={"md"}
              style={{
                textAlign: "center",
                color: "white",
                fontFamily: "Raleway",
              }}
            >
              Login
            </Title>
            <Text
              mb={"md"}
              style={{
                textAlign: "center",
                color: "white",
                fontFamily: "Raleway",
              }}
            >
              Sign in to your account
            </Text>
            <TextInput
              style={{
                fontFamily: "RaleWay",
              }}
              icon=<IconMail />
              mb={"md"}
              type="email"
              placeholder="Email"
              required
              autoComplete="off"
              onChange={(event) => setEmail(event.currentTarget.value)}
            />

            <TextInput
              icon=<IconLock />
              style={{ marginBottom: "16px" }}
              type="password"
              placeholder="Password"
              required
              autoComplete="off"
              onChange={(event) => setPassword(event.currentTarget.value)}
            />

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "16px", // Add margin top for spacing
              }}
            >
              <Grid.Col span={4}>
                <Button
                  style={{
                    color: "black",
                    paddingLeft: window.innerWidth < 700 ? "8px" : "1.125rem",
                    paddingRight: window.innerWidth < 700 ? "8px" : "1.125rem",
                    fontSize: window.innerWidth < 700 ? 13 : 16,
                    fontFamily: "Raleway",
                  }}
                  variant="gradient"
                  gradient={{ from: "white", to: "#FFFC15", deg: 60 }}
                  onClick={() => handleLogin()}
                  disabled={isLoading}
                >
                  Log In
                  <LoadingOverlay
                    visible={isLoading}
                    overlayOpacity={0.8}
                    loaderProps={{ size: 24 }}
                  ></LoadingOverlay>
                </Button>
              </Grid.Col>
            </div>

            <Grid>
              <Grid.Col span="content">
                <Text
                  mt={"md"}
                  style={{
                    color: "#4a54c5",
                    cursor: "pointer",
                    fontSize: window.innerWidth < 768 ? "13px" : "16px",
                    fontFamily: "Raleway",
                  }}
                  onClick={handleResetPassModal}
                >
                  Forgot Password?
                </Text>
              </Grid.Col>
            </Grid>

            <Grid>
              <Grid.Col span="content">
                <Text
                  style={{
                    fontSize: window.innerWidth < 768 ? "13px" : "16px",
                    color: "white",
                    fontFamily: "Raleway",
                  }}
                >
                  You don't have an account?
                </Text>
              </Grid.Col>
              <Grid.Col span="content">
                <Text
                  style={{
                    color: "#4a54c5",
                    cursor: "pointer",
                    fontSize: window.innerWidth < 768 ? "13px" : "16px",
                    fontFamily: "Raleway",
                  }}
                  onClick={handleSignUp}
                >
                  Register
                </Text>
              </Grid.Col>
            </Grid>
          </Card>
          <div
            style={{
              marginTop: window.innerWidth < 768 ? "10%" : "3%",
              marginLeft: "auto",
              marginRight: "auto",
              paddingTop: "1%",
              display: window.innerWidth < 768 ? "block" : "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "70%",
              textAlign: "justify",
              fontSize: window.innerWidth < 768 ? "0.8rem" : "1rem",
              color: "white",
              fontWeight: "100",
            }}
          >
            Do you have any questions? Email to
            <Text
              style={{
                fontWeight: "bold",
                marginLeft: window.innerWidth < 768 ? "0px" : "4px",
                fontFamily: "Raleway",
              }}
            >
              serbay@aiarticlewriter.org
            </Text>
          </div>
        </Grid.Col>
      </Grid>
      <div
        style={{
          marginTop: window.innerWidth < 768 ? "10%" : "3%",
          marginLeft: "auto",
          marginRight: "auto",
          paddingBottom: "2%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "70%",
          textAlign: "justify",
          fontSize: window.innerWidth < 768 ? "0.8rem" : "1rem",
        }}
      >
        <IconCopyright color="white" />
        <Text
          color="gray"
          style={{
            marginLeft: "0.5%",
            fontWeight: "100",
            fontFamily: "Raleway",
            color: "white",
          }}
        >
          ArticleWriter. All rights reserved.
        </Text>
      </div>
    </div>
  );
};

export default LoginPage;
