import {
  Button,
  Container,
  Divider,
  Grid,
  Modal,
  TextInput,
  Textarea,
  Text,
  Loader,
  Tooltip,
  MediaQuery,
  Burger,
  useMantineTheme,
} from "@mantine/core";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import {
  clearSecureLocalStorage,
  getSecureLocalStorage,
  isTokenValid,
} from "../utilities/functions";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { IconAlertCircle, IconCircleCheck } from "@tabler/icons-react";
import styles from "../css/CssLoader.module.css";
import { ChatMessage, ChatRoom } from "../models/ChatRoom";

const CustomChatBot = () => {
  const [driveFolderURL, setDriveFolderURL] = useState("");
  const [driveFolderURLInputValue, setDriveFolderURLInputValue] = useState("");
  const [chatInput, setChatInput] = useState("");
  const [folderURLInputDisabled, setFolderURLInputDisabled] = useState(true);
  const [userModelExists, setUserModelExists] = useState(false);
  const [chatResponses, setChatResponses] = useState<ChatMessage[]>([]);

  const chatResponsesDivRef = useRef<HTMLDivElement>(null);
  const [openLoadingModal, setOpenLoadingModal] = useState<boolean>(false);
  const [openConfirmationModal, setOpenConfirmationModal] =
    useState<boolean>(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isChatMessagesLoading, setIsChatMessagesLoading] =
    useState<boolean>(false);
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
  const [modelResponseIsWaiting, setModelResponseIsWaiting] =
    useState<boolean>(false);
  const [currentChatRoom, setCurrentChatRoom] = useState<ChatRoom>();
  const [userChatRooms, setUserChatRooms] = useState<ChatRoom[]>([]);
  const [chatRoomsBurgerOpen, setChatRoomsBurgerOpen] =
    useState<boolean>(true);

  const theme = useMantineTheme();
  const navigate = useNavigate();

  useEffect(() => {
    const token = getSecureLocalStorage("item1");

    if (!token || !isTokenValid(token)) {
      clearSecureLocalStorage();
      navigate("/", { state: { tokenExpired: true } });
    }
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await axios.get(
          "https://app.aiarticlewriter.org/get-user-chatrooms",
          {
            withCredentials: true,
          }
        );

        const userDriveFolderURL = response.data.model_folder_url;
        const userModelStatus = response.data.modelExists;
        const userChatRooms: ChatRoom[] = response.data.user_chatRooms;

        setUserChatRooms(userChatRooms.reverse());
        setUserModelExists(userModelStatus);
        if (userDriveFolderURL != null) {
          setDriveFolderURL(userDriveFolderURL);
        } else {
          setDriveFolderURL("");
        }
        setIsLoading(false);
      } catch {
        setDriveFolderURL("");
        setIsLoading(false);
      }
    }
    fetchData();
  }, [navigate]);

  const handleSendInputToModel = async () => {
    if (chatInput.length > 0) {
      setModelResponseIsWaiting(true);
      let prevResponses = [...chatResponses];
      prevResponses.push({
        content: chatInput,
        sentByUser: true,
      });
      setChatResponses(prevResponses);
      setChatInput("");

      let currentChatRoomId = -1;
      if (currentChatRoom) {
        currentChatRoomId = currentChatRoom.id;
      }

      let requestParams = {
        input: chatInput,
        chatRoomId: currentChatRoomId,
      };
      try {
        const response = await axios.post(
          "https://app.aiarticlewriter.org/get-model-response",
          requestParams,
          {
            withCredentials: true,
          }
        );

        const modelResponse = response.data.model_response;
        const userChatRooms: ChatRoom[] = response.data.user_chatRooms;
        const currentChatRoom: ChatRoom = response.data.currentChatRoom;
        setCurrentChatRoom(currentChatRoom)
        setUserChatRooms(userChatRooms.reverse());

        prevResponses = [...prevResponses];
        prevResponses.push({
          content: modelResponse,
          sentByUser: false,
        });
        setChatResponses(prevResponses);
        setModelResponseIsWaiting(false);
      } catch {
        setDriveFolderURL("");
        setModelResponseIsWaiting(false);
      }
    }
  };

  const fetchChatRoomMessages = async (chatRoomId: number) => {
    if(window.innerWidth < 700) {
      setChatRoomsBurgerOpen(false);
    }
    setIsChatMessagesLoading(true);
    let requestParams = {
      chatRoomId: chatRoomId,
    };
    try {
      const response = await axios.post(
        "https://app.aiarticlewriter.org/get-chatroom-messages",
        requestParams,
        {
          withCredentials: true,
        }
      );

      const currentChatRoom: ChatRoom = response.data.current_chatroom_messages;
      setCurrentChatRoom(currentChatRoom);
      setChatResponses(currentChatRoom.chat_messages);
      setIsChatMessagesLoading(false);
    } catch (e) {
      setIsChatMessagesLoading(false);
    }
  };

  const createNewChatRoom = () => {
    setChatResponses([]);
    setCurrentChatRoom(undefined);
  };

  const handleTrainModel = async () => {
    setOpenConfirmationModal(false);
    setOpenLoadingModal(true);

    if (!driveFolderURL.startsWith("https://drive.google.com/drive/folders/")) {
      setOpenLoadingModal(false);
      setOpenErrorModal(true);
      return;
    }

    let requestParams = {
      folderURL: driveFolderURL,
    };

    try {
      await axios.post("https://app.aiarticlewriter.org/train-model", requestParams, {
        withCredentials: true,
      });

      setOpenLoadingModal(false);
      setOpenSuccessModal(true);
      setUserModelExists(true);
    } catch (error: any) {
      setOpenLoadingModal(false);
      setOpenErrorModal(true);
    }
  };

  useEffect(() => {
    if (chatResponsesDivRef.current) {
      chatResponsesDivRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [chatResponses]);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: window.innerWidth > 724 ? "100px" : "0px",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div style={{ height: "88%" }}>
          <Modal
            opened={openLoadingModal}
            onClose={() => setOpenLoadingModal(false)}
            withCloseButton={false}
            centered
            padding={20}
            withOverlay={false}
            withinPortal={true}
          >
            <Grid align="center">
              <Grid.Col span={12}>
                <Text style={{ fontFamily: "Raleway", color: "black" }}>
                  Your model is being trained. This may take a few minutes...
                </Text>
              </Grid.Col>
            </Grid>
            <Grid align="center" justify="center">
              <Loader />
            </Grid>
          </Modal>

          <Modal
            opened={openConfirmationModal}
            onClose={() => setOpenConfirmationModal(false)}
            withCloseButton={false}
            centered
            padding={20}
            withOverlay={false}
            withinPortal={true}
          >
            <Grid align="center">
              <Grid.Col span={12}>
                <Text style={{ fontFamily: "Raleway", color: "black" }}>
                  Make sure that the folder has the permission to 'Anyone with the link can view'. Do you still want to train a model with given folder?
                </Text>
              </Grid.Col>
            </Grid>

            <Grid
              align="center"
              justify="flex-end"
              style={{ gap: "10px", marginTop: "10px" }}
            >
              <Button
                color="red"
                onClick={() => setOpenConfirmationModal(false)}
              >
                Cancel
              </Button>
              <Button color="green" onClick={handleTrainModel}>
                Yes
              </Button>
            </Grid>
          </Modal>

          <Modal
            opened={openErrorModal}
            onClose={() => setOpenConfirmationModal(false)}
            withCloseButton
            centered
            padding={20}
            withOverlay={false}
            withinPortal={true}
          >
            <Grid align="center">
              <Grid.Col span={12} style={{ textAlign: "center" }}>
                <IconAlertCircle color="red" size={"60px"}></IconAlertCircle>
                <Text style={{ fontFamily: "Raleway", color: "black" }}>
                  An error has occured while training the model. Please check
                  your folder URL!
                </Text>
              </Grid.Col>
            </Grid>

            <Grid
              align="center"
              justify="flex-end"
              style={{ gap: "10px", marginTop: "10px" }}
            >
              <Button color="blue" onClick={() => setOpenErrorModal(false)}>
                Ok
              </Button>
            </Grid>
          </Modal>

          <Modal
            opened={openSuccessModal}
            onClose={() => setOpenSuccessModal(false)}
            withCloseButton
            centered
            padding={20}
            withOverlay={false}
            withinPortal={true}
          >
            <Grid align="center">
              <Grid.Col span={12} style={{ textAlign: "center" }}>
                <IconCircleCheck color="green" size={"60px"}></IconCircleCheck>
                <Text style={{ fontFamily: "Raleway", color: "black" }}>
                  Your model is successfully trained! You can start sending
                  messages!
                </Text>
              </Grid.Col>
            </Grid>

            <Grid
              align="center"
              justify="flex-end"
              style={{ gap: "10px", marginTop: "10px" }}
            >
              <Button color="green" onClick={() => setOpenSuccessModal(false)}>
                Ok
              </Button>
            </Grid>
          </Modal>

          <Container
            display={"flex"}
            style={{
              justifyContent: "center",
              gap: window.innerWidth < 700 ? "5px" : "20px",
            }}
          >
            <TextInput
              placeholder={
                driveFolderURL === "" ? "Drive Folder URL" : driveFolderURL
              }
              disabled={folderURLInputDisabled}
              style={{
                width: "50%",
              }}
              labelProps={{
                style: {
                  fontFamily: "Raleway",
                  color: "white",
                  fontWeight: "bold",
                },
              }}
              value={driveFolderURLInputValue}
              onChange={(event) => {
                setDriveFolderURLInputValue(event.currentTarget.value);
              }}
            />

            <Button
              style={{
                color: "black",
                paddingLeft: window.innerWidth < 700 ? "8px" : "1.125rem",
                paddingRight: window.innerWidth < 700 ? "8px" : "1.125rem",
                fontSize: window.innerWidth < 700 ? 13 : 16,
                display: folderURLInputDisabled ? "block" : "none",
              }}
              variant="gradient"
              gradient={{ from: "white", to: "#03c2fc", deg: 60 }}
              onClick={() => setFolderURLInputDisabled(false)}
            >
              Edit
            </Button>

            <Button
              style={{
                color: "black",
                paddingLeft: window.innerWidth < 700 ? "8px" : "1.125rem",
                paddingRight: window.innerWidth < 700 ? "8px" : "1.125rem",
                fontSize: window.innerWidth < 700 ? 13 : 16,
                display: folderURLInputDisabled ? "none" : "block",
              }}
              variant="gradient"
              gradient={{ from: "white", to: "#db0033", deg: 60 }}
              onClick={() => {
                setFolderURLInputDisabled(true);
                setDriveFolderURLInputValue(driveFolderURL);
              }}
            >
              Cancel
            </Button>

            <Button
              style={{
                color: "black",
                paddingLeft: window.innerWidth < 700 ? "8px" : "1.125rem",
                paddingRight: window.innerWidth < 700 ? "8px" : "1.125rem",
                fontSize: window.innerWidth < 700 ? 13 : 16,
                display: folderURLInputDisabled ? "none" : "block",
              }}
              variant="gradient"
              hidden={folderURLInputDisabled}
              gradient={{ from: "white", to: "#1ced31", deg: 60 }}
              onClick={() => {
                setDriveFolderURL(driveFolderURLInputValue);
                setFolderURLInputDisabled(true);
              }}
            >
              Save
            </Button>

            <Button
              style={{
                color: "black",
                paddingLeft: window.innerWidth < 700 ? "8px" : "1.125rem",
                paddingRight: window.innerWidth < 700 ? "8px" : "1.125rem",
                fontSize: window.innerWidth < 700 ? 13 : 16,
              }}
              variant="gradient"
              gradient={{ from: "white", to: "#FFFC15", deg: 60 }}
              onClick={() => setOpenConfirmationModal(true)}
            >
              Train Model
            </Button>
          </Container>
          <Divider my="sm" />

          <div style={{ height: "100%", padding: "0px", display: "flex" }}>
            <div
              style={{
                borderRight: "1px solid white",
                width: chatRoomsBurgerOpen && window.innerWidth < 700 ? "100%" : !chatRoomsBurgerOpen && window.innerWidth < 700 ? "5%" : "18%",
                alignItems: "center",
                padding: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <MediaQuery largerThan="sm" styles={{ display: "none" }}>
                <Burger
                  opened={chatRoomsBurgerOpen}
                  onClick={() => setChatRoomsBurgerOpen((o) => !o)}
                  size="sm"
                  color={theme.colors.gray[6]}
                  mb="xl"
                />
              </MediaQuery>
              <div
                style={{
                  display: chatRoomsBurgerOpen ? "flex" : "none",
                  width: "100%",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                }}
              >
                <Button
                  style={{
                    background: "transparent",
                    border: "2px solid white",
                    width: "100%",
                  }}
                  onClick={createNewChatRoom}
                >
                  + New Chat Room
                </Button>
              </div>
              {userChatRooms.map((chatRoom, index) => (
                <div
                  style={{
                    display: chatRoomsBurgerOpen ? "flex" : "none",
                    flexWrap: "wrap",
                    justifyContent: "flex-start",
                    width: "100%",
                  }}
                >
                  {" "}
                  <Button
                    style={{
                      background: "transparent",
                      width: "100%",
                      border: "2px solid white",
                      marginTop: "10px",
                    }}
                    onClick={() => fetchChatRoomMessages(chatRoom.id)}
                  >
                    {
                      chatRoom.chat_messages[
                        chatRoom.chat_messages.filter(
                          (item) => item.sentByUser === true
                        ).length - 1
                      ].content
                    }
                  </Button>
                </div>
              ))}
            </div>

            {isChatMessagesLoading ? (
              <div
                style={{
                  width: "70%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: window.innerWidth > 724 ? "100px" : "0px",
                }}
              >
                <Loader />
              </div>
            ) : (
              <div
                style={{
                  width: "70%",
                  flexDirection: "column",
                  display: "flex",
                  padding: "15px",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    alignSelf: "flex-start",
                    flex: 1,
                    overflow: "auto",
                    maxHeight: "400px",
                    width: "100%",
                    paddingRight: "40px",
                    marginBottom: "10px"
                  }}
                >
                  {chatResponses.map((chatResponse, index) => (
                    <div
                      key={index}
                      style={{
                        textAlign: chatResponse.sentByUser ? "right" : "left",
                        color: "black",
                        backgroundColor: chatResponse.sentByUser
                          ? "#9cbdf7"
                          : "#cbdbf7",
                        padding: "10px 20px",
                        borderRadius: "5px",
                        marginBottom: "10px",
                        flexWrap: "wrap",
                        wordBreak: "break-word",
                        fontSize: window.innerWidth < 700 ? 13 : 16,
                      }}
                    >
                      {chatResponse.content
                        .split("\n")
                        .map((line, lineIndex) => (
                          <React.Fragment key={lineIndex}>
                            {lineIndex > 0 && <br />}
                            {line}
                          </React.Fragment>
                        ))}
                    </div>
                  ))}
                  <div ref={chatResponsesDivRef}></div>
                </div>
                <div
                  className={styles.dots1}
                  style={{
                    marginBottom: "30px",
                    display: modelResponseIsWaiting ? "block" : "none",
                  }}
                ></div>
                <div
                  style={{
                    alignSelf: "flex-end",
                    display: "flex",
                    gap: window.innerWidth < 700 ? "5px" : "20px",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Textarea
                    placeholder="Write your input..."
                    style={{
                      width: "90%",
                    }}
                    labelProps={{
                      style: {
                        fontFamily: "Raleway",
                        color: "white",
                        fontWeight: "bold",
                      },
                    }}
                    value={chatInput}
                    onChange={(event) => {
                      setChatInput(event.currentTarget.value);
                    }}
                  />

                  <Tooltip
                    label="You don't have any model! Train a model first!"
                    display={userModelExists ? "none" : "block"}
                  >
                    <Button
                      style={{
                        color: "black",
                        fontSize: window.innerWidth < 700 ? 13 : 16,
                        cursor:
                          !userModelExists || chatInput.length === 0
                            ? "not-allowed"
                            : "pointer",
                      }}
                      variant="gradient"
                      gradient={{ from: "white", to: "#FFFC15", deg: 60 }}
                      onClick={() => {
                        if (userModelExists) {
                          handleSendInputToModel();
                        }
                      }}
                    >
                      Send
                    </Button>
                  </Tooltip>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CustomChatBot;
