import {
  Alert,
  Button,
  Container,
  Grid,
  Loader,
  LoadingOverlay,
  Modal,
  Space,
  Table,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import "../../styles/tables.css";
import { useEffect, useState } from "react";
import {
  getSecureLocalStorage,
  isTokenValid,
  clearSecureLocalStorage,
} from "../../utilities/functions";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  IconAlertCircle,
  IconExclamationCircle,
  IconTrash,
} from "@tabler/icons-react";
import { CouponWithUser } from "../../models/Coupon";

const CouponsPage = () => {
  const navigate = useNavigate();
  const [showPage, setShowPage] = useState<boolean>(false);
  const [coupons, setCoupons] = useState<CouponWithUser[]>([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [couponAmount, setCouponAmount] = useState<string>("");
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [openAddCouponModal, setOpenAddCouponModal] = useState<boolean>(false);
  const [isCouponResponseWaiting, setIsCouponResponseWaiting] =
    useState<boolean>(false);
  const [expirationDate, setExpirationDate] = useState<string>("1");
  const [couponTokenAmount, setCouponTokenAmount] = useState<string>("50000");
  const [couponAlreadyExists, setCouponAlreadyExists] =
    useState<boolean>(false);

  const handleAddCouponModal = () => {
    setOpenAddCouponModal(true);
  };

  useEffect(() => {
    const token = getSecureLocalStorage("item1");

    if (!token || !isTokenValid(token)) {
      clearSecureLocalStorage();
      navigate("/", { state: { tokenExpired: true } });
    }
  }, [navigate]);

  useEffect(() => {
    async function fetchCoupons() {
      try {
        const response = await axios.get("https://app.aiarticlewriter.org/get-coupons", {
          withCredentials: true,
        });

        const allCoupons: CouponWithUser[] = response.data.data;
        setCoupons(allCoupons);
      } catch {
        clearSecureLocalStorage();
        navigate("/", { state: { tokenExpired: true } });
      }
      setShowPage(true);
    }
    fetchCoupons();
  }, [navigate]);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleCouponEntrance = async () => {
    try {
      setIsCouponResponseWaiting(true);
      const param = {
        couponAmount: couponAmount,
        tokenAmount: couponTokenAmount,
        expirationDate: expirationDate,
      };
      const response = await axios.post(
        "https://app.aiarticlewriter.org/create-coupon",
        param,
        {
          withCredentials: true,
        }
      );

      const errorMessage = response.data.status;
      if (errorMessage === undefined) {
        setCouponAlreadyExists(false);
        const couponsInfo: CouponWithUser[] = response.data.data;
        setCoupons(couponsInfo);
      } else {
        setCouponAlreadyExists(true);
      }

      setIsCouponResponseWaiting(false);
      setOpenAddCouponModal(false);
      setCouponAmount("");
      setExpirationDate("1");
    } catch (e) {
      setCouponAmount("");
      setExpirationDate("1");
      setOpenAddCouponModal(false);
      setOpenErrorModal(true);
      setIsCouponResponseWaiting(false);
    }
  };

  const handleCouponDeletion = async (code: string) => {
    try {
      const response = await axios.delete(
        "https://app.aiarticlewriter.org/delete-coupon",
        {
          withCredentials: true,
          data: {
            code: code,
          },
        }
      );

      const allCoupons: CouponWithUser[] = response.data.data;
      setCoupons(allCoupons);
    } catch {
      setOpenErrorModal(true);
    }
  };

  const startIndex = (currentPage - 1) * 10;
  const endIndex = startIndex + 10;
  const displayedItems = coupons.slice(startIndex, endIndex);

  const resultTable = displayedItems.map((coupon: CouponWithUser) => (
    <>
      <tr key={coupon.value}>
        <td id="usersTable">{coupon.value}</td>
        <td id="usersTable">
          {coupon.tokenAmount
            ? coupon.tokenAmount.toLocaleString()
            : coupon.tokenAmount}
        </td>
        <td id="usersTable">{coupon.status}</td>
        <td id="usersTable">{coupon.created_at.toLocaleString()}</td>
        <td id="usersTable">{coupon.expired_at.toLocaleString()}</td>
        <td id="usersTable">{coupon.usedBy}</td>
        <td id="usersTable">
          <IconTrash
            color="red"
            style={{ cursor: "pointer" }}
            onClick={() => handleCouponDeletion(coupon.value)}
          />
        </td>
      </tr>
    </>
  ));

  return (
    <>
      {showPage ? (
        <>
          {couponAlreadyExists ? (
            <Alert
              icon={<IconAlertCircle size="1rem" />}
              title="Error!"
              color="red"
            >
              Coupon already exists!
            </Alert>
          ) : (
            <div></div>
          )}
          <Modal
            opened={openErrorModal}
            onClose={() => setOpenErrorModal(false)}
            withCloseButton={false}
            centered
            padding={20}
            withinPortal={true}
            style={{ backgroundColor: "red" }}
          >
            <Grid align="center" justify="center">
              <Grid.Col span={3}>
                <IconExclamationCircle color="red" size={30} />
              </Grid.Col>
              <Grid.Col span={9}>
                <Text>An error has occured. Please try again!</Text>
              </Grid.Col>
            </Grid>
          </Modal>
          <Modal
            opened={openAddCouponModal}
            size={window.innerWidth < 700 ? 275 : 350}
            title=<Text
              style={{
                fontWeight: "bold",
                fontSize: window.innerWidth < 700 ? 20 : 24,
              }}
            >
              Add Coupon
            </Text>
            onClose={() => {
              setCouponAmount("");
              setOpenAddCouponModal(false);
            }}
            withCloseButton
            centered
            padding={20}
            withinPortal={true}
          >
            <TextInput
              mb={"md"}
              value={couponAmount}
              size={window.innerWidth < 700 ? "xs" : "md"}
              label="Coupon Amount"
              placeholder="How many coupons?"
              required
              autoComplete="off"
              onChange={(event) => setCouponAmount(event.currentTarget.value)}
            />

            <Space h={"md"} />

            <TextInput
              mb={"md"}
              type="number"
              value={couponTokenAmount}
              size={window.innerWidth < 700 ? "xs" : "md"}
              label="Token Amount"
              placeholder="Enter the token amount"
              required
              autoComplete="off"
              onChange={(event) =>
                setCouponTokenAmount(event.currentTarget.value)
              }
            />
            <Space h={"md"} />

            <TextInput
              mb={"md"}
              type="number"
              value={expirationDate}
              size={window.innerWidth < 700 ? "xs" : "md"}
              label="Expiration Day"
              placeholder="Enter the expiration day"
              required
              autoComplete="off"
              onChange={(event) => setExpirationDate(event.currentTarget.value)}
            />
            <div style={{ justifyContent: "center", display: "flex" }}>
              <Button
                color="green"
                onClick={() => handleCouponEntrance()}
                disabled={
                  couponAmount === "" ||
                  expirationDate === "" ||
                  couponTokenAmount === ""
                }
                style={{
                  fontSize: window.innerWidth < 700 ? "0.75rem" : "1rem",
                }}
              >
                Add{" "}
                <LoadingOverlay
                  visible={isCouponResponseWaiting}
                  overlayOpacity={0.8}
                  loaderProps={{ size: 24 }}
                ></LoadingOverlay>
              </Button>
            </div>
          </Modal>
          <Title
            style={{
              fontSize: window.innerWidth < 762 ? "24px" : "36px",
              color: "white",
              fontFamily: "Raleway",
            }}
          >
            Coupons
          </Title>
          <Space h="md" />
          <div style={{ justifyContent: "center", display: "flex" }}>
            <Button
              style={{
                color: "black",
                fontFamily: "Raleway",
                justifyContent: "center",
              }}
              type="submit"
              variant="gradient"
              gradient={{ from: "white", to: "#FFFC15", deg: 60 }}
              onClick={handleAddCouponModal}
            >
              Add Coupon
            </Button>
          </div>
          <Space h="md" />
          {coupons.length > 0 ? (
            <>
              <div>
                <Space h={"xl"} />
                <Table
                  style={{
                    tableLayout: "fixed",
                    borderBottomColor: "#ccc",
                    borderBottomWidth: "0.01em",
                    borderBottomStyle: "solid",
                  }}
                >
                  <colgroup
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <col style={{ width: "23%" }} />
                    <col
                      style={{ width: window.innerWidth < 700 ? "27%" : "20%" }}
                    />
                    <col
                      style={{ width: window.innerWidth < 700 ? "27%" : "20%" }}
                    />
                    <col
                      style={{ width: window.innerWidth < 700 ? "20%" : "20%" }}
                    />
                    <col style={{ width: "20%" }} />
                    <col style={{ width: "20%" }} />
                    <col style={{ width: "20%" }} />
                    <col
                      style={{ width: window.innerWidth < 700 ? "10%" : "20%" }}
                    />
                    <col style={{ width: "10%" }} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th id="usersTable">Coupon Code</th>
                      <th id="usersTable">Token Amount</th>
                      <th id="usersTable">Status</th>
                      <th id="usersTable">Created Time</th>
                      <th id="usersTable">Expired Time</th>
                      <th id="usersTable">Used By</th>
                      <th style={{ wordBreak: "break-word" }}></th>
                    </tr>
                  </thead>
                  <tbody>{resultTable}</tbody>
                </Table>
                <Space h="md" />
              </div>
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                {Array.from(
                  { length: Math.ceil(coupons.length / 10) },
                  (_, index) => index + 1
                ).map((pageNumber) => (
                  <Button
                    size="xs"
                    key={pageNumber}
                    onClick={() => handlePageChange(pageNumber)}
                    disabled={pageNumber === currentPage}
                    ml={"md"}
                  >
                    {pageNumber}
                  </Button>
                ))}
              </div>
            </>
          ) : (
            <>
              <Text style={{ fontStyle: "italic", textAlign: "center" }}>
                No coupons created!
              </Text>
            </>
          )}
        </>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: window.innerWidth > 724 ? "100px" : "0px",
          }}
        >
          <Loader />
        </div>
      )}
    </>
  );
};

export default CouponsPage;
