import {
  Button,
  Grid,
  Loader,
  LoadingOverlay,
  MantineProvider,
  Mark,
  Menu,
  Modal,
  Space,
  Table,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import "../../styles/tables.css";
import { useEffect, useState } from "react";
import {
  getSecureLocalStorage,
  isTokenValid,
  clearSecureLocalStorage,
} from "../../utilities/functions";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { UserView } from "../../models/User";
import {
  IconCircleMinus,
  IconCirclePlus,
  IconDotsVertical,
  IconExclamationCircle,
  IconQuestionMark,
  IconTrash,
} from "@tabler/icons-react";

const Users = () => {
  const navigate = useNavigate();
  const [showPage, setShowPage] = useState<boolean>(false);
  const [users, setUsers] = useState<UserView[]>([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [currentUser, setCurrentUser] = useState<string>("");
  const [currentUserRemainingToken, setCurrentUserRemainingToken] =
    useState<number>(0);
  const [currentUserTotalToken, setCurrentUserTotalToken] = useState<number>(0);
  const [addTokenAmountStr, setAddTokenAmountStr] = useState<string>("");
  const [addTokenAmount, setAddTokenAmount] = useState<number>(0);

  const [removeTokenAmountStr, setRemoveTokenAmountStr] = useState<string>("");
  const [removeTokenAmount, setRemoveTokenAmount] = useState<number>(0);

  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isUserTokenUpdating, setIsUserTokenUpdating] =
    useState<boolean>(false);

  const [openAddTokenModal, setOpenAddTokenModal] = useState<boolean>(false);
  const [openRemoveTokenModal, setOpenRemoveTokenModal] =
    useState<boolean>(false);
  const [openConfirmationModal, setOpenConfirmationModal] =
    useState<boolean>(false);

  useEffect(() => {
    const token = getSecureLocalStorage("item1");

    if (!token || !isTokenValid(token)) {
      clearSecureLocalStorage();
      navigate("/", { state: { tokenExpired: true } });
    }
  }, [navigate]);

  useEffect(() => {
    async function fetchUsers() {
      try {
        const response = await axios.get("https://app.aiarticlewriter.org/get-users", {
          withCredentials: true,
        });

        const allUsers: UserView[] = response.data.data;
        setUsers(allUsers);
        setOpenConfirmationModal(false);
      } catch {
        clearSecureLocalStorage();
        navigate("/", { state: { tokenExpired: true } });
      }
      setShowPage(true);
    }
    fetchUsers();
  }, [navigate]);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleAddTokenToUser = async () => {
    setIsUserTokenUpdating(true);
    try {
      const updatedToken = currentUserTotalToken + addTokenAmount;
      let requestParams = {
        email: currentUser,
        updatedToken: updatedToken,
      };

      const response = await axios.put(
        "https://app.aiarticlewriter.org/update-user-token-amount",
        requestParams,
        {
          withCredentials: true,
        }
      );

      const allUsers: UserView[] = response.data.data;
      setUsers(allUsers);
    } catch {
      setOpenErrorModal(true);
    }
    setAddTokenAmountStr("");
    setOpenAddTokenModal(false);
    setIsUserTokenUpdating(false);
  };

  const handleRemoveTokenFromUser = async () => {
    setIsUserTokenUpdating(true);
    try {
      const updatedToken = currentUserTotalToken - removeTokenAmount;
      let requestParams = {
        email: currentUser,
        updatedToken: updatedToken,
      };
      const response = await axios.put(
        "https://app.aiarticlewriter.org/update-user-token-amount",
        requestParams,
        {
          withCredentials: true,
        }
      );

      const allUsers: UserView[] = response.data.data;
      setUsers(allUsers);
    } catch {
      setOpenErrorModal(true);
    }
    setRemoveTokenAmountStr("");
    setOpenRemoveTokenModal(false);
    setIsUserTokenUpdating(false);
  };

  const handleDeleteUser = async (email: string) => {
    setIsLoading(true);
    try {
      const response = await axios.delete("https://app.aiarticlewriter.org/delete-user", {
        withCredentials: true,
        data: {
          email: email,
        },
      });

      const allUsers: UserView[] = response.data.data;
      setUsers(allUsers);
      setIsLoading(false);
      setOpenConfirmationModal(false);
    } catch {
      setOpenErrorModal(true);
    }
  };

  const handleOpenConfirmationModal = async (email: string) => {
    setCurrentUser(email);
    setOpenConfirmationModal(true);
  };

  const handleCloseConfirmationModal = async () => {
    setCurrentUser("");
    setOpenConfirmationModal(false);
  };

  const startIndex = (currentPage - 1) * 10;
  const endIndex = startIndex + 10;
  const displayedItems = users.slice(startIndex, endIndex);

  const resultTable = displayedItems.map((user: UserView) => (
    <>
      <tr key={user.email}>
        <td id="usersTable">{user.email}</td>
        <td id="usersTable">{user.creationTime}</td>
        <td id="usersTable">{user.generatedContentNumber}</td>
        <td id="usersTable">{user.totalTokenAmount.toLocaleString()}</td>
        <td id="usersTable">{user.usedTokenAmount.toLocaleString()}</td>
        <td id="usersTable">
          {(user.totalTokenAmount - user.usedTokenAmount).toLocaleString()}
        </td>
        <td id="usersTable">${user.totalCost.toFixed(2)}</td>
        <td id="usersIcons">
          <Grid justify="">
            <Grid.Col span="content">
              <Menu shadow="md" width={200}>
                <Menu.Target>
                  <div style={{ cursor: "pointer" }}>
                    <IconDotsVertical
                      size={window.innerWidth < 762 ? 20 : 24}
                    />{" "}
                  </div>
                </Menu.Target>

                <Menu.Dropdown>
                  <Menu.Item
                    icon={<IconCirclePlus size={16} color="green" />}
                    onClick={() => {
                      setCurrentUser(user.email);
                      setCurrentUserRemainingToken(
                        user.totalTokenAmount - user.usedTokenAmount
                      );
                      setCurrentUserTotalToken(user.totalTokenAmount);
                      setOpenAddTokenModal(true);
                    }}
                  >
                    Add Token
                  </Menu.Item>
                  <Menu.Item
                    icon={<IconCircleMinus size={16} color="red" />}
                    onClick={() => {
                      setCurrentUser(user.email);
                      setCurrentUserRemainingToken(
                        user.totalTokenAmount - user.usedTokenAmount
                      );
                      setCurrentUserTotalToken(user.totalTokenAmount);
                      setOpenRemoveTokenModal(true);
                    }}
                  >
                    Remove Token
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Grid.Col>
            <Grid.Col span="content">
              <IconTrash
                size={window.innerWidth < 762 ? 20 : 24}
                color="red"
                style={{ cursor: "pointer" }}
                onClick={() => handleOpenConfirmationModal(user.email)}
              />
            </Grid.Col>
          </Grid>
        </td>
      </tr>
    </>
  ));

  return (
    <MantineProvider
      theme={{
        components: {
          Text: {
            styles: (theme, params) => ({
              root: {
                color: "white",
              },
            }),
          },
          Table: {
            styles: (theme, params) => ({
              root: {
                color: "white",
                fontFamily: "Raleway",
              },
            }),
          },
          th: {
            styles: (theme, params) => ({
              root: {
                color: "white",
                fontFamily: "Raleway",
              },
            }),
          },
          Button: {
            styles: (theme, params) => ({
              root: {
                color: "white",
                fontFamily: "Raleway",
              },
            }),
          },
        },
      }}
    >
      {showPage ? (
        <>
          <Modal
            opened={openErrorModal}
            onClose={() => setOpenErrorModal(false)}
            withCloseButton={false}
            centered
            padding={20}
            withinPortal={true}
            style={{ backgroundColor: "red" }}
          >
            <Grid align="center" justify="center">
              <Grid.Col span={3}>
                <IconExclamationCircle color="red" size={30} />
              </Grid.Col>
              <Grid.Col span={9}>
                <Text>An error has occured. Please try again!</Text>
              </Grid.Col>
            </Grid>
          </Modal>

          <Modal
            opened={openAddTokenModal}
            size={window.innerWidth < 700 ? 275 : 350}
            title=<Text
              style={{
                fontWeight: "bold",
                fontSize: window.innerWidth < 700 ? 20 : 24,
                fontFamily: "Raleway",
                color: "black",
              }}
            >
              Add Token
            </Text>
            onClose={() => {
              setAddTokenAmountStr("");
              setOpenAddTokenModal(false);
            }}
            withCloseButton
            centered
            padding={20}
            withinPortal={true}
          >
            <TextInput
              mb={"md"}
              value={addTokenAmountStr}
              size={window.innerWidth < 700 ? "xs" : "md"}
              label="Token Amount"
              placeholder="Enter the token amount"
              required
              autoComplete="off"
              onChange={(event) => {
                const input = event.currentTarget.value.replace(/,/g, ""); // Remove existing commas
                const number = Number(input); // Convert to number
                if (!isNaN(number)) {
                  setAddTokenAmount(number);
                  const formattedInput = number.toLocaleString(); // Add thousand separators
                  setAddTokenAmountStr(formattedInput);
                }
              }}
            />
            <Text
              style={{
                fontSize: window.innerWidth < 700 ? "0.75rem" : "1rem",
                fontFamily: "Raleway",
                color: "black",
              }}
            >
              Updated Token Amount:{" "}
              <Mark>
                {(currentUserRemainingToken + addTokenAmount).toLocaleString()}
              </Mark>
            </Text>
            <Space h={"md"} />
            <div style={{ justifyContent: "center", display: "flex" }}>
              <Button
                color="green"
                onClick={() => handleAddTokenToUser()}
                disabled={addTokenAmountStr === "" || addTokenAmount === 0}
                style={{
                  fontSize: window.innerWidth < 700 ? "0.75rem" : "1rem",
                }}
              >
                Update{" "}
                <LoadingOverlay
                  visible={isUserTokenUpdating}
                  overlayOpacity={0.8}
                  loaderProps={{ size: 24 }}
                ></LoadingOverlay>
              </Button>
            </div>
          </Modal>
          <Modal
            size={window.innerWidth < 700 ? 275 : 350}
            opened={openRemoveTokenModal}
            title=<Text
              style={{
                fontWeight: "bold",
                fontSize: window.innerWidth < 700 ? 20 : 24,
                fontFamily: "Raleway",
                color: "black",
              }}
            >
              Remove Token
            </Text>
            onClose={() => {
              setRemoveTokenAmountStr("");
              setOpenRemoveTokenModal(false);
            }}
            withCloseButton
            centered
            padding={20}
            withinPortal={true}
          >
            <TextInput
              mb={"md"}
              value={removeTokenAmountStr}
              size={window.innerWidth < 700 ? "xs" : "md"}
              label="Token Amount"
              placeholder="Enter the token amount"
              required
              autoComplete="off"
              onChange={(event) => {
                console.log(event.currentTarget.value)
                const input = event.currentTarget.value.replace(/,/g, ""); // Remove existing commas
                const number = Number(input); // Convert to number
                if (!isNaN(number) && currentUserRemainingToken >= number) {
                  setRemoveTokenAmount(number);
                  const formattedInput = number.toLocaleString(); // Add thousand separators
                  setRemoveTokenAmountStr(formattedInput);
                }
              }}
            />
            <Text
              style={{
                fontSize: window.innerWidth < 700 ? "0.75rem" : "1rem",
                fontFamily: "Raleway",
                color: "black",
              }}
            >
              Updated Token Amount:{" "}
              <Mark>
                {(
                  currentUserRemainingToken - removeTokenAmount
                ).toLocaleString()}
              </Mark>
            </Text>
            <Space h={"md"} />
            <div style={{ justifyContent: "center", display: "flex" }}>
              <Button
                color="red"
                onClick={() => handleRemoveTokenFromUser()}
                disabled={
                  removeTokenAmountStr === "" || removeTokenAmount === 0
                }
                style={{
                  fontSize: window.innerWidth < 700 ? "0.75rem" : "1rem",
                }}
              >
                Update{" "}
                <LoadingOverlay
                  visible={isUserTokenUpdating}
                  overlayOpacity={0.8}
                  loaderProps={{ size: 24 }}
                ></LoadingOverlay>
              </Button>
            </div>
          </Modal>
          <div style={{ maxWidth: "50%" }}>
            <Modal
              size={window.innerWidth < 700 ? "xs" : "md"}
              opened={openConfirmationModal}
              onClose={() => setOpenConfirmationModal(false)}
              centered
              withinPortal={true}
            >
              <Grid align="center" justify="center">
                <Grid.Col span={3}>
                  <IconQuestionMark size={30} />
                </Grid.Col>
                <Grid.Col span={9}>
                  <Text style={{ color: "black" }}>
                    Are you sure to delete the user?
                  </Text>
                </Grid.Col>
                <Grid justify="">
                  <Grid.Col span="content">
                    <Button
                      onClick={() => handleDeleteUser(currentUser)}
                      disabled={isLoading}
                    >
                      Yes
                      <LoadingOverlay
                        visible={isLoading}
                        overlayOpacity={0.8}
                        loaderProps={{ size: 24 }}
                      ></LoadingOverlay>
                    </Button>
                  </Grid.Col>
                  <Grid.Col span="content">
                    <Button
                      onClick={handleCloseConfirmationModal}
                      disabled={isLoading}
                    >
                      No
                    </Button>
                  </Grid.Col>
                </Grid>
              </Grid>
            </Modal>
          </div>
          <Title
            style={{ fontSize: window.innerWidth < 762 ? "24px" : "36px" }}
          >
            Users
          </Title>
          <Space h="md" />
          {users.length > 0 ? (
            <>
              <div>
                <Table
                  style={{
                    tableLayout: "fixed",
                    borderBottomColor: "#ccc",
                    borderBottomWidth: "0.01em",
                    borderBottomStyle: "solid",
                  }}
                >
                  <colgroup>
                    <col style={{ width: "23%" }} />
                    <col
                      style={{ width: window.innerWidth < 700 ? "27%" : "20%" }}
                    />
                    <col
                      style={{ width: window.innerWidth < 700 ? "27%" : "20%" }}
                    />
                    <col
                      style={{ width: window.innerWidth < 700 ? "20%" : "20%" }}
                    />
                    <col style={{ width: "20%" }} />
                    <col style={{ width: "20%" }} />
                    <col style={{ width: "20%" }} />
                    <col
                      style={{ width: window.innerWidth < 700 ? "10%" : "20%" }}
                    />
                  </colgroup>
                  <thead>
                    <tr>
                      <th id="usersTable">Email</th>
                      <th id="usersTable">Creation Date</th>
                      <th id="usersTable">Content Amount</th>
                      <th id="usersTable">Total Token</th>
                      <th id="usersTable">Used Token</th>
                      <th id="usersTable">Remaining Token</th>
                      <th id="usersTable">Total Cost</th>
                      <th id="usersTable"></th>
                    </tr>
                  </thead>
                  <tbody>{resultTable}</tbody>
                </Table>
                <Space h="md" />
              </div>
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  marginRight: window.innerWidth > 724 ? "120px" : "0",
                }}
              >
                {Array.from(
                  { length: Math.ceil(users.length / 10) },
                  (_, index) => index + 1
                ).map((pageNumber) => (
                  <Button
                    size="xs"
                    key={pageNumber}
                    variant="gradient"
                    gradient={{ from: "white", to: "#FFFC15", deg: 60 }}
                    style={{ color: "black" }}
                    onClick={() => handlePageChange(pageNumber)}
                    disabled={pageNumber === currentPage}
                    ml={"md"}
                  >
                    {pageNumber}
                  </Button>
                ))}
              </div>
            </>
          ) : (
            <Text italic style={{ textAlign: "center" }}>
              No users registered!
            </Text>
          )}
        </>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: window.innerWidth > 724 ? "100px" : "0px",
          }}
        >
          <Loader />
        </div>
      )}
    </MantineProvider>
  );
};

export default Users;
