import {
  Title,
  Text,
  Grid,
  Space,
  Card,
  TextInput,
  Paper,
  Select,
  Group,
  CloseButton,
  Button,
  Modal,
  Loader,
  Table,
  Textarea,
  Radio,
  Alert,
  MantineProvider,
} from "@mantine/core";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { IconAlertCircle, IconCircleCheckFilled } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import {
  clearSecureLocalStorage,
  getSecureLocalStorage,
  isTokenValid,
} from "../utilities/functions";
import styles from "../css/styles.module.css";

export interface returnData {
  keyword: string;
  url: string;
}

const HomePage = () => {
  const [model, setModel] = useState("GPT-3.5");
  const [language, setLanguage] = useState("English");
  const [tone, setTone] = useState("Formal");
  const [wordCount, setWordCount] = useState("3000");
  const [keywords, setKeywords] = useState<string[]>([]);
  const [keywordHeadings, setKeywordHeadings] = useState<string[]>([]);
  const [contentTrainingURLs, setContentTrainingURLs] = useState<string[]>([]);
  const [singleKeyword, setSingleKeyword] = useState<string>("");

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [generateButtonClicked, setGenerateButtonClicked] =
    useState<boolean>(false);
  const [urls, setURLs] = useState<string[]>([]);
  const tableRef = useRef<HTMLTableElement>(null);
  const [keywordType, setKeywordType] = useState("ai");
  const [contentTrainingMethod, setContentTrainingMethod] =
    useState("googleTopResults");
  const [manualHeadings, setManualHeadings] = useState<string>("");
  const [showPage, setShowPage] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    const token = getSecureLocalStorage("item1");

    if (!token || !isTokenValid(token)) {
      clearSecureLocalStorage();
      navigate("/", { state: { tokenExpired: true } });
    } else {
      setShowPage(true);
    }
  }, [navigate]);

  const handleModelChange = (value: string) => {
    setModel(value ? value : "");
  };

  const handleLanguageChange = (value: string) => {
    setLanguage(value ? value : "");
  };

  const handleToneChange = (value: string) => {
    setTone(value ? value : "");
  };

  const handleKeywordTypehange = (value: string) => {
    setKeywordType(value ? value : "");
  };

  const handlecontentTrainingMethodChange = (value: string) => {
    setContentTrainingMethod(value ? value : "");
  };

  const handleSingleKeywordChange = (value: string) => {
    setSingleKeyword(value ? value : "");
  };

  const handleSingleKeywordContentTrainingURLsChange = (
    url: string,
    keyword: string
  ) => {
    const index = keywords.indexOf(keyword);
    let prevValues = [...contentTrainingURLs];
    prevValues[index] = url;
    setContentTrainingURLs(prevValues);
  };

  const handleSingleKeywordHeadingsChange = (
    headings: string,
    keyword: string
  ) => {
    const index = keywords.indexOf(keyword);
    let prevValues = [...keywordHeadings];
    prevValues[index] = headings;
    setKeywordHeadings(prevValues);
  };

  const handleManualHeadingsChange = (value: string) => {
    setManualHeadings(value ? value : "");
  };

  const handleReturnPressed = () => {
    if (singleKeyword.length > 2 && !keywords.includes(singleKeyword)) {
      let prevValues = [...keywords];
      prevValues.push(singleKeyword);
      setKeywords(prevValues);
      setSingleKeyword("");
    }
  };

  const handleClosePressed = (keyword: string) => {
    const index = keywords.indexOf(keyword);
    const updatedArray = [...keywords];
    const updatedContentTrainingURLs = [...contentTrainingURLs];
    const updatedKeywordHeadings = [...keywordHeadings];
    updatedArray.splice(index, 1);
    updatedContentTrainingURLs.splice(index, 1);
    updatedKeywordHeadings.splice(index, 1);
    setContentTrainingURLs(updatedContentTrainingURLs);
    setKeywordHeadings(updatedKeywordHeadings);
    setKeywords(updatedArray);
  };

  const handleSubmit = async () => {
    setOpenModal(true);
    setGenerateButtonClicked(true);
    let contentURLs = contentTrainingURLs;
    if (contentTrainingURLs.length === 0) {
      contentURLs = Array(keywords.length).fill("");
    }
    let manualHeadings = keywordHeadings;
    if (keywordHeadings.length === 0) {
      manualHeadings = Array(keywords.length).fill("");
    }

    let requestParams = {
      keywords: keywords,
      headings: manualHeadings,
      model: model,
      language: language,
      tone: tone,
      wordCount: wordCount,
      contentTrainingURLs: contentURLs,
    };

    try {
      const response = await axios.post(
        "https://app.aiarticlewriter.org/generate-content",
        requestParams,
        {
          withCredentials: true,
        }
      );

      const responseData = response.data;

      const returnedURLS = responseData.map((item: returnData) => item.url);
      const returnedKeywords = responseData.map(
        (item: returnData) => item.keyword
      );

      setKeywords(returnedKeywords);
      setURLs(returnedURLS);
      setOpenModal(false);
    } catch (error: any) {
      setOpenModal(false);
      setOpenAlert(true);
    }
  };

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [urls]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [openAlert]);

  const getFontSize = () => {
    if (window.innerWidth < 768) {
      return "1.8rem"; // Adjust the font size for mobile devices
    }
    return "2.4rem"; // Default font size for larger screens
  };

  const getContentWidth = () => {
    if (window.innerWidth < 768) {
      return "90%"; // Adjust the font size for mobile devices
    }
    return "45%"; // Default font size for larger screens
  };

  const getExplanationSize = () => {
    if (window.innerWidth < 768) {
      return "1rem"; // Adjust the font size for mobile devices
    }
    return "1.2rem"; // Default font size for larger screens
  };

  const getAdvantagesSize = () => {
    if (window.innerWidth < 768) {
      return "0.8rem"; // Adjust the font size for mobile devices
    }
    return "1.1rem"; // Default font size for larger screens
  };

  const resultTable = urls.map((url: string, index: number) => (
    <tr key={url}>
      <td style={{ wordBreak: "break-word", color: "white", fontFamily: "Raleway" }}>{keywords[index]}</td>
      <td style={{ wordBreak: "break-word", color: "#4a54c5", fontFamily: "Raleway" }}>
        <a href={url} target="_blank" rel="noopener noreferrer">
          {url}
        </a>
      </td>
    </tr>
  ));

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.width = "auto";
      textareaRef.current.style.width = `${textareaRef.current.scrollWidth}px`;
    }
  }, []);

  return (
    <div
      style={{
        color: "white",
        fontFamily: "Raleway",
      }}
    >
      <MantineProvider
        theme={{
          components: {
            Text: {
              defaultProps: {
                fontFamily: "Raleway",
                color: "white",
              },
            },
            Table: {
              defaultProps: {
                fontFamily: "Raleway",
                color: "white",
              },
            },
            Radio: {
              defaultProps: {
                fontFamily: "Raleway",
                color: "white",
              },
            },
          },
        }}
      >
        {openAlert ? (
          <Alert
            icon={<IconAlertCircle size="1rem" />}
            title="Error!"
            color="red"
          >
            An error occured, please try again!
          </Alert>
        ) : (
          <div></div>
        )}
        {showPage ? (
          <>
            <Modal
              opened={openModal}
              onClose={() => setOpenModal(false)}
              withCloseButton={false}
              centered
              padding={20}
              withOverlay={false}
              withinPortal={true}
            >
              <Grid align="center">
                <Grid.Col span={12}>
                  <Text style={{ fontFamily: "Raleway", color: "black" }}>
                    Your contents are being created. This may take a few
                    minutes...
                  </Text>
                </Grid.Col>
              </Grid>
              <Grid align="center" justify="center">
                <Loader />
              </Grid>
            </Modal>

            <Grid justify="center" align="center">
              <Grid.Col span={8}>
                <Title
                  style={{
                    marginBottom: "1rem",
                    marginTop: "1rem",
                    textAlign: "center",
                    fontSize: getFontSize(),
                    color: "white",
                    fontFamily: "Raleway",
                  }}
                >
                  Unlock the power of AI-Written Articles
                </Title>
              </Grid.Col>
            </Grid>
            <Grid justify="center" align="center">
              <Grid.Col span={8}>
                <Text
                  style={{
                    color: "white",
                    fontFamily: "Raleway",
                    marginBottom: "1rem",
                    textAlign: "center",
                    fontSize: getExplanationSize(),
                  }}
                >
                  Our platform leverages artificial intelligence to produce
                  captivating, high-quality content for businesses and content
                  creators. Experience the future of content creation today.
                </Text>
              </Grid.Col>
              <Grid.Col span={10}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Group>
                    <IconCircleCheckFilled color="black" size={20} />
                    <Text style={{ fontSize: getAdvantagesSize() }}>
                      2,000 words with high quality{" "}
                    </Text>
                  </Group>
                </div>
                <Space h={"md"} />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Group>
                    <IconCircleCheckFilled color="black" size={20} />
                    <Text style={{ fontSize: getAdvantagesSize() }}>
                      Supported by GPT-3.5 and GPT-4
                    </Text>
                  </Group>
                </div>
                <Space h={"md"} />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Group>
                    <IconCircleCheckFilled color="black" size={20} />
                    <Text style={{ fontSize: getAdvantagesSize() }}>
                      Powered by Frase
                    </Text>
                  </Group>
                </div>
              </Grid.Col>
              <Space h="md" />
              <Grid.Col span={12}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Card
                    style={{
                      width: getContentWidth(),
                      alignItems: "center",
                      backgroundColor: "transparent",
                    }}
                  >
                    <Grid justify="center">
                      <Grid.Col span={12}>
                        <Paper style={{ backgroundColor: "transparent" }}>
                          <Select
                            placeholder="Select GPT model"
                            label="Model:"
                            labelProps={{
                              style: {
                                fontFamily: "Raleway",
                                color: "white",
                                fontWeight: "bold",
                              },
                            }}
                            data={[
                              { value: "GPT-3.5", label: "GPT-3.5" },
                              { value: "GPT-4", label: "GPT-4" },
                            ]}
                            nothingFound="No model found"
                            value={model}
                            onChange={handleModelChange}
                            disabled={generateButtonClicked}
                          />
                        </Paper>
                      </Grid.Col>
                      <Space h="md" />
                      <Grid.Col span={12}>
                        <Paper style={{ backgroundColor: "transparent" }}>
                          <Select
                            placeholder="Select your language"
                            label="Language:"
                            labelProps={{
                              style: {
                                fontFamily: "Raleway",
                                color: "white",
                                fontWeight: "bold",
                              },
                            }}
                            data={[
                              { value: "English", label: "English" },
                              { value: "Turkish", label: "Turkish" },
                              { value: "German", label: "German" },
                              { value: "Spanish", label: "Spanish" },
                              { value: "French", label: "French" },
                              { value: "Italian", label: "Italian" },
                            ]}
                            nothingFound="No model found"
                            value={language}
                            onChange={handleLanguageChange}
                            disabled={generateButtonClicked}
                          />
                        </Paper>
                      </Grid.Col>
                      <Space h="md" />
                      <Grid.Col span={12}>
                        <Paper style={{ backgroundColor: "transparent" }}>
                          <Select
                            placeholder="Select your tone"
                            label="Tone:"
                            labelProps={{
                              style: {
                                fontFamily: "Raleway",
                                color: "white",
                                fontWeight: "bold",
                              },
                            }}
                            data={[
                              { value: "Formal", label: "Formal" },
                              { value: "Informal", label: "Informal" },
                              { value: "Friendly", label: "Friendly" },
                              { value: "Humorous", label: "Humorous" },
                              {
                                value: "Conversational",
                                label: "Conversational",
                              },
                            ]}
                            nothingFound="No model found"
                            value={tone}
                            onChange={handleToneChange}
                            disabled={generateButtonClicked}
                          />
                        </Paper>
                      </Grid.Col>
                      <Space h="md" />
                      <Grid.Col span={12}>
                        <Paper style={{ backgroundColor: "transparent" }}>
                          <TextInput
                            placeholder="Select maximum word count"
                            label="Max Word Count:"
                            labelProps={{
                              style: {
                                fontFamily: "Raleway",
                                color: "white",
                                fontWeight: "bold",
                              },
                            }}
                            type="number"
                            value={wordCount}
                            onChange={(event) => {
                              const input = event.currentTarget.value.replace(
                                /,/g,
                                ""
                              );
                              const number = Number(input);
                              if (!isNaN(number) && number <= 5000) {
                                setWordCount(input);
                              }
                            }}
                            disabled={generateButtonClicked}
                          />
                          <Text italic size={"sm"}>
                            You can generate a maximum of 5,000 words of
                            content.
                          </Text>
                        </Paper>
                      </Grid.Col>
                      <Space h={"md"} />
                      <Grid.Col span={12}>
                        <Paper style={{ backgroundColor: "transparent" }}>
                          <TextInput
                            label="Keyword (Press Enter to add):"
                            labelProps={{
                              style: {
                                fontFamily: "Raleway",
                                color: "white",
                                fontWeight: "bold",
                              },
                            }}
                            onKeyDown={(event) => {
                              if (event.key === "Enter") {
                                handleReturnPressed();
                              }
                            }}
                            placeholder="Enter a keyword with at least 3 letters"
                            onChange={(event) =>
                              handleSingleKeywordChange(
                                event.currentTarget.value
                              )
                            }
                            value={singleKeyword}
                            disabled={generateButtonClicked}
                            error={
                              singleKeyword.length < 3 ||
                              keywords.includes(singleKeyword)
                            }
                          />
                        </Paper>
                      </Grid.Col>
                      <Grid.Col span={12}>
                        <>
                          {keywords.map((keyword) => (
                            <>
                              <Textarea
                                autosize
                                disabled
                                style={{ width: "100%" }}
                                styles={{
                                  input: {
                                    border: "2px solid #cccccc",
                                    borderRadius: 0,
                                    WebkitTextFillColor: "black",
                                    fontFamily: "Raleway",
                                    color: "white",
                                  },
                                }}
                                rightSection={
                                  <CloseButton
                                    aria-label="Remove tag"
                                    disabled={generateButtonClicked}
                                    onClick={() => handleClosePressed(keyword)}
                                  />
                                }
                                ref={textareaRef}
                              >
                                {keyword}
                              </Textarea>
                              <Space h="sm" />
                            </>
                          ))}
                        </>
                      </Grid.Col>
                      <Space h="sm" />
                      <Grid.Col span={12}>
                        <Space h={"md"} />
                        <Radio.Group
                          name="keywordTypeSelection"
                          label="Select your headings generation choice"
                          labelProps={{
                            style: {
                              fontFamily: "Raleway",
                              color: "white",
                              fontWeight: "bold",
                            },
                          }}
                          withAsterisk
                          value={keywordType}
                          onChange={handleKeywordTypehange}
                        >
                          <Group mt="xs">
                            <Radio
                              value="ai"
                              label=<Text
                                style={{
                                  fontFamily: "Raleway",
                                  color: "white",
                                }}
                              >
                                Generate headings with AI model
                              </Text>
                            />
                            <Radio
                              value="manual"
                              label=<Text
                                style={{
                                  fontFamily: "Raleway",
                                  color: "white",
                                }}
                              >
                                Enter headings manually
                              </Text>
                            />
                          </Group>
                        </Radio.Group>
                      </Grid.Col>
                      <Space h="md" />
                      {keywordType === "ai" ? (
                        <></>
                      ) : (
                        <>
                          {keywords.length === 0 ? (
                            <Grid.Col span={12}>
                              <Text italic size={"sm"} align="center">
                                You need to add keywords first!
                              </Text>
                            </Grid.Col>
                          ) : (
                            <Grid.Col span={12}>
                              {keywords.map((keyword) => (
                                <>
                                  <Grid.Col span={12}>
                                    <Textarea
                                      label={`Headings for the keyword: ${keyword}`}
                                      labelProps={{
                                        style: {
                                          fontFamily: "Raleway",
                                          color: "white",
                                          fontWeight: "bold",
                                        },
                                      }}
                                      minRows={6}
                                      placeholder="Enter your headings seperated by new line."
                                      onChange={(event) =>
                                        handleSingleKeywordHeadingsChange(
                                          event.currentTarget.value,
                                          keyword
                                        )
                                      }
                                      value={
                                        keywordHeadings[
                                          keywords.indexOf(keyword)
                                        ]
                                      }
                                      disabled={generateButtonClicked}
                                    />
                                    <Space h="sm" />
                                  </Grid.Col>
                                </>
                              ))}
                            </Grid.Col>
                          )}
                        </>
                      )}
                      <Grid.Col span={12}>
                        <Radio.Group
                          name="contentTrainingMethodSelection"
                          label="Select your content training method"
                          withAsterisk
                          labelProps={{
                            style: {
                              fontFamily: "Raleway",
                              color: "white",
                              fontWeight: "bold",
                            },
                          }}
                          value={contentTrainingMethod}
                          onChange={handlecontentTrainingMethodChange}
                        >
                          <Group mt="xs">
                            <Radio
                              value="googleTopResults"
                              label=<Text
                                style={{
                                  fontFamily: "Raleway",
                                  color: "white",
                                }}
                              >
                                Use Google Top Results to train the content
                              </Text>
                            />
                            <Radio
                              value="usersURL"
                              label=<Text
                                style={{
                                  fontFamily: "Raleway",
                                  color: "white",
                                }}
                              >
                                Enter website URL's to train the content
                              </Text>
                            />
                          </Group>
                        </Radio.Group>
                      </Grid.Col>
                      {contentTrainingMethod === "usersURL" ? (
                        <>
                          {keywords.length === 0 ? (
                            <Grid.Col span={12}>
                              <Text italic size={"sm"} align="center">
                                You need to add keywords first!
                              </Text>
                            </Grid.Col>
                          ) : (
                            <Grid.Col span={12}>
                              {keywords.map((keyword) => (
                                <>
                                  <Grid.Col span={12}>
                                    <Textarea
                                      label={`Content training URLs for the keyword: ${keyword}`}
                                      labelProps={{
                                        style: {
                                          fontFamily: "Raleway",
                                          color: "white",
                                          fontWeight: "bold",
                                        },
                                      }}
                                      minRows={6}
                                      placeholder="Enter your URLs seperated by new line."
                                      onChange={(event) =>
                                        handleSingleKeywordContentTrainingURLsChange(
                                          event.currentTarget.value,
                                          keyword
                                        )
                                      }
                                      value={
                                        contentTrainingURLs[
                                          keywords.indexOf(keyword)
                                        ]
                                      }
                                      disabled={generateButtonClicked}
                                      //error={contentTrainingURLs[keywords.indexOf(keyword)].length < 3}
                                    />
                                  </Grid.Col>
                                  <Space h="sm" />
                                </>
                              ))}
                            </Grid.Col>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                      <div style={{ justifyContent: "center" }}>
                        <Grid.Col span={4}>
                          <Button
                            style={{
                              color: "black",
                              fontFamily: "Raleway",
                            }}
                            type="submit"
                            variant="gradient"
                            gradient={{ from: "white", to: "#FFFC15", deg: 60 }}
                            onClick={handleSubmit}
                            disabled={
                              generateButtonClicked ||
                              keywords.length === 0 ||
                              (contentTrainingURLs.filter(
                                (item) => item.length === 0
                              ).length > 0 &&
                                contentTrainingMethod === "usersURL") ||
                              (contentTrainingURLs.length !== keywords.length &&
                                contentTrainingMethod === "usersURL") ||
                              (keywordType === "manual" &&
                                keywordHeadings.filter(
                                  (item) => item.length === 0
                                ).length > 0) ||
                              (keywordType === "manual" &&
                                keywordHeadings.length !== keywords.length)
                            }
                          >
                            Generate Content
                          </Button>
                        </Grid.Col>
                      </div>
                      {urls.length > 0 ? (
                        <Grid.Col span={12}>
                          <Space h="sm" />
                          <Table
                            ref={tableRef}
                            style={{ tableLayout: "fixed" }}
                          >
                            <colgroup>
                              <col style={{ width: "50%" }} />
                              <col style={{ width: "50%" }} />
                            </colgroup>
                            <thead>
                              <tr>
                                <th style={{ wordBreak: "break-word", fontFamily: "Raleway", color: "white" }}>
                                  Keyword
                                </th>
                                <th style={{ wordBreak: "break-word", fontFamily: "Raleway", color: "white" }}>
                                  Generated URL
                                </th>
                              </tr>
                            </thead>
                            <tbody>{resultTable}</tbody>
                          </Table>
                          <Space h="md" />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Text style={{ fontSize: getAdvantagesSize(), fontFamily: "Raleway" }}>
                              (Refresh the page to generate another content)
                            </Text>
                          </div>
                        </Grid.Col>
                      ) : (
                        <Space h="md" />
                      )}
                    </Grid>
                  </Card>
                </div>
              </Grid.Col>
            </Grid>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: window.innerWidth > 724 ? "100px" : "0px",
            }}
          >
            <Loader />
          </div>
        )}
      </MantineProvider>
    </div>
  );
};

export default HomePage;
